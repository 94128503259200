import { Download } from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Margin, usePDF } from "react-to-pdf";
import { baseURL } from "../../Config/BaseUrl";
import { useSnackbar } from "notistack";
import CustomButton from "../../Shared/CustomButton";

export default function InvoicePDF() {
  const { pdf: invoice_id, storeId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const invoicesListFn = useCallback(() => {
    try {
      const response = axios.get(
        `${baseURL}/customer-pannel/customer-invoice-details-api-in-bhaaraterp/?page=1&store_id=${storeId}&invoice_id=${invoice_id}`,
        {
          headers: {
            authorization: localStorage.getItem("customersToken"),
          },
        }
      );

      return response;
    } catch (error) {
      enqueueSnackbar(error.message, {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
    }
  }, [enqueueSnackbar, invoice_id, storeId]);

  const { data: invoiceById, isLoading } = useQuery(
    ["invoice", invoice_id],
    () => invoicesListFn({ invoice_id: invoice_id }),
    { enabled: Number(invoice_id) ? true : false }
  );
  const invoices = invoiceById?.data?.data;
  const store = invoices?.store;



  const { toPDF, targetRef } = usePDF({
    filename: `INV-${invoices?.id}.pdf`,
    page: { margin: Margin.MEDIUM },
  });

  const height = document.getElementById("container")?.offsetHeight;

  return isLoading ? (
    <div className="flex items-center justify-center w-full h-full">
      <CircularProgress size={55} />
    </div>
  ) : (
    <div className="flex flex-row justify-center p-4 bg-gray-200">
      <div
        style={{ height: `${height + 60}px` }}
        className="relative h-[200vh] overflow-hidden w-full max-w-[1024px]"
      >
        <div className="border-2 lg:px-10  bg-white w-[100%]">
          <span
            className={classNames(
              "absolute left-[-50px] p-2 !pl-5 text-center text-white -rotate-45 bg-blue-500 w-44 top-7",
              invoices?.payment_status === "Partial Paid"
                ? "bg-yellow-500"
                : invoices?.payment_status === "Un Paid"
                  ? "bg-red-500"
                  : invoices?.payment_status === "Paid"
                    ? "bg-green-500"
                    : "bg-gray-500"
            )}
          >
            {invoices?.payment_status}
          </span>

          <div className="flex items-end justify-end w-full lg:py-5 ">
            <div className="grid flex-row items-center justify-center lg:flex ">
              <CustomButton
                className="!bg-purple-400 !rounded-md !text-blue-600"
                onClick={toPDF}
                startIcon={<Download />}
              >
                Download
              </CustomButton>
            </div>
          </div>
          <div className="w-full h-full px-5 py-5" id="container">
            <div className="flex-row items-center justify-between lg:flex">
              <div className="flex flex-col my-5">
                <div>
                  <img className="h-32" alt="" src={store?.logo} />
                </div>
                <div className="flex flex-col">
                  <p className="text-2xl font-bold underline">
                    {store?.store_name}
                  </p>
                </div>
                <div className="flex">
                  <p className="font-bold">City :</p>
                  <p className="px-3">{store?.city}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">State :</p>
                  <p className="px-3">{store?.state}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Country :</p>
                  <p className="px-3">{store?.country}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Currency :</p>
                  <p className="px-3">{store?.currency}</p>
                </div>

                <div className="flex">
                  <p className="font-bold">Mob No :</p>
                  <p className="px-3">{store?.store_mobile}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">GSTIN :</p>
                  <p className="px-3">{store?.gst_number}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Area :</p>
                  <p className="px-3">{store?.store_address}</p>
                </div>
              </div>
              <div className="flex flex-col my-5">
                <div className="flex">
                  <p className="text-4xl font-bold capitalize">TAX INVOICE</p>
                </div>
                <div className="flex justify-end w-full">
                  <p className="flex font-bold">{invoices?.invoice_no}</p>
                </div>
              </div>
            </div>
            <Divider />

            <div className="flex-row items-center justify-between lg:flex">
              <div className="flex flex-col my-5 ">
                <div className="flex">
                  <p className="text-2xl font-bold underline">Bill To</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Company :</p>
                  <p className="px-3">{invoices?.customer?.company_name}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Customer :</p>
                  <p className="px-3">
                    {invoices?.customer?.first_name +
                      " " +
                      invoices?.customer?.last_name || ""}
                  </p>
                </div>
                <div className="flex">
                  <p className="font-bold">Area :</p>
                  <p className="px-3">{invoices?.customer?.area}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">City :</p>
                  <p className="px-3">{invoices?.customer?.city}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">State :</p>
                  <p className="px-3">{invoices?.customer?.state}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Country :</p>
                  <p className="px-3">{invoices?.customer?.nationality}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">PAN :</p>
                  <p className="px-3">{invoices?.customer?.pan_number}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">GSTIN :</p>
                  <p className="px-3">{invoices?.customer?.gst_number}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Place of Supply :</p>
                  <p className="px-3">{invoices?.customer?.source_of_supply}</p>
                </div>
              </div>
              <div className="flex flex-col my-5">
                <div className="flex">
                  <p className="font-bold">Invoice Date :</p>
                  <p className="px-3">{invoices?.date}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Terms :</p>
                  <p className="px-3">{invoices?.pay_terms}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Due Date :</p>
                  <p className="px-3">{invoices?.due_date}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Reference# :</p>
                  <p className="px-3">{invoices?.reference_no}</p>
                </div>
              </div>
            </div>
            <Divider />
            <div>
              <div className="lg:!w-[100%]  md:!w-[30%] !gap-0 border-2 border-black">
                <Table size="small" className="!shadow-none ">
                  <TableHead className="lg:!w-![100%]  md:w-![30%]  border-b-2 border-black">
                    <TableRow>
                      <TableCell className="!font-bold !text-black border-r border-black ">
                        #
                      </TableCell>
                      <TableCell className="!font-bold !text-black   border-r border-black ">
                        Items
                      </TableCell>

                      <TableCell className="!font-bold !text-black   border-r border-black ">
                        Image
                      </TableCell>
                      <TableCell className="!font-bold !text-black  border-r border-black">
                        HSN/SAC
                      </TableCell>
                      <TableCell className="!font-bold !text-black  border-r border-black">
                        Qnt
                      </TableCell>
                      <TableCell className="!font-bold !text-black  border-r border-black">
                        Rate
                      </TableCell>
                      {invoices?.tax === "GST" ? (
                        <>
                          <TableCell className="!font-bold !text-black  border-r border-black ">
                            SGST
                          </TableCell>
                          <TableCell className="!font-bold !text-black  border-r border-black ">
                            CGST
                          </TableCell>
                        </>
                      ) : (
                        <TableCell className="!font-bold !text-black  border-r border-black ">
                          IGST
                        </TableCell>
                      )}
                      <TableCell className="!font-bold !text-black   ">
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {invoices?.invoice_item?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className="lg:!py-3 !text-black !print:text-black  border-r border-black ">
                          {index + 1}
                        </TableCell>
                        <TableCell className="lg:!py-3 !text-black  border-r border-black ">
                          {row?.item_name}({row?.slug})
                        </TableCell>
                        <TableCell className="lg:!py-3 !text-black  border-r border-black ">

                          <img src={store?.logo} alt="storeLogo" className="h-16 w-28 " />


                        </TableCell>
                        <TableCell className="!py-3 !text-black  border-r border-black">
                          {row?.hsn}
                        </TableCell>
                        <TableCell className="!py-3 !text-black  border-r border-black">
                          {row?.quantity}
                        </TableCell>
                        <TableCell className="!py-3 !text-black  border-r border-black">
                          {store?.currency} {row?.rate}
                        </TableCell>
                        {invoices?.tax === "GST" ? (
                          <>
                            <TableCell className="!py-3 !text-black  border-r border-black ">
                              {store?.currency} {row?.sgst ? row?.sgst : "--"}
                            </TableCell>
                            <TableCell className="!py-3 !text-black  border-r border-black ">
                              {store?.currency} {row?.cgst ? row?.cgst : "--"}
                            </TableCell>
                          </>
                        ) : (
                          <TableCell className="!py-3 !text-black  border-r border-black ">
                            {store?.currency}{" "}
                            {row?.igst ? row?.igst : row?.cgst + row?.sgst}
                          </TableCell>
                        )}
                        <TableCell className="!py-3 !text-black  ">
                          {store?.currency} {row?.total_price}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>







              <div className="flex-row items-start justify-between w-full my-5 lg:flex">
                <span className="w-1/2">
                  <p className="font-bold">Terms and Conditions :</p>
                  <p>Pay according to Terms and conditions.</p>
                </span>
                <div className="flex flex-col lg:w-[50%] w-full my-3">
                  <div className="flex flex-row items-center justify-between w-full p-2">
                    <p className="!font-bold">Total Quantity</p>
                    <p>
                      {invoices?.invoice_item?.reduce(
                        (a, b) => a + b?.quantity,
                        0
                      )}
                    </p>
                  </div>
                  <Divider className="!my-1" />
                  <div className="flex flex-row items-center justify-between w-full p-2">
                    <p className="!font-bold">Sub Total</p>
                    <p>
                      {store?.currency} {invoices?.total_price}
                    </p>
                  </div>
                  <Divider className="!my-1" />

                  {invoices?.tax === "IGST" ? (
                    <div className="flex flex-row items-center justify-between w-full p-2">
                      <p className="!font-bold">IGST</p>
                      <p>
                        {store?.currency}{" "}
                        {invoices?.igst
                          ? invoices?.igst
                          : invoices?.cgst + invoices?.sgst}
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-row items-center justify-between w-full p-2">
                        <p className="!font-bold">CGST</p>
                        <p>
                          {store?.currency} {invoices?.cgst}
                        </p>
                      </div>
                      <Divider className="!my-1" />
                      <div className="flex flex-row items-center justify-between w-full p-2">
                        <p className="!font-bold">SGST</p>
                        <p>
                          {store?.currency} {invoices?.sgst}
                        </p>
                      </div>
                    </>
                  )}
                  <Divider className="!my-1" />
                  <div className="flex items-center justify-between w-full p-2">
                    <p className="font-bold">Total Amount</p>
                    <p>
                      {store?.currency} {invoices?.total_of_discount_plus_ship}
                    </p>
                  </div>
                  <Divider className="!my-1" />
                  {invoices?.payment_amount?.map((paid) => {
                    return (
                      <>
                        <div className="flex flex-row items-center justify-between w-full p-2">
                          <p className="font-bold">Paid</p>
                          <p>
                            {store?.currency}{" "}
                            <span className="text-red-500">{paid}</span>
                          </p>
                        </div>
                        <Divider className="!my-1" />
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-row bg-[#F5F4F3] items-center justify-end w-full p-2">
                <span className="flex justify-between w-1/2">
                  <p className="font-bold">Balance Due</p>
                  <p>
                    {store?.currency} {invoices?.remaining_amount}
                  </p>
                </span>
              </div>
              <Divider />
              <div className="flex-row items-center justify-between w-full my-5 lg:flex">
                <div className="flex flex-col w-full">
                  <p className="font-bold">Authorised Signature</p>
                  <img src={store?.signature} alt="" className="w-32 h-32" />
                </div>
                <div className="flex flex-col justify-center w-full">
                  <p className="font-bold">Scan UPI for payment</p>
                  <img src={store?.upi_code} alt="" className="w-32 h-32 p-2" />
                </div>
                <div className="grid w-full grid-cols-1 lg:items-end lg:my-0">
                  <p className="my-2 font-bold">
                    Bank Details are Given Below :
                  </p>
                  <div className="flex flex-row my-2">
                    <p className="font-bold">Bank name :</p>
                    <p className="px-2">{store?.bank_name}</p>
                  </div>
                  <div className="flex flex-row my-2">
                    <p className="font-bold">Bank A/c. No. :</p>
                    <p className="px-2">{store?.account_no}</p>
                  </div>
                  <div className="flex flex-row my-2">
                    <p className="font-bold">IFSC Code :</p>
                    <p className="px-2">{store?.IFSC}</p>
                  </div>
                  <div className="flex flex-row my-2">
                    <p className="font-bold">Swift Code :</p>
                    <p className="px-2">{store?.swift_code}</p>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="flex flex-col lg:w-[50%] py-2 w-full">
                <p className="font-bold">Note : </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: invoices?.description,
                  }}
                  className="whitespace-break-spaces"
                ></p>
              </div>
            </div>
          </div>






          <div
            ref={targetRef}
            className="block w-[1080px] mt-[200vh] lg:px-5 lg:py-5"
          >

            <span
              className={classNames(
                " p-2 !pl-5 text-center text-whitebg-blue-500 w-full   text-xl font-semibold flex justify-center items-center",
                invoices?.payment_status === "Partial Paid"
                  ? "text-yellow-600"
                  : invoices?.payment_status === "Un Paid"
                    ? "text-red-700 "
                    : invoices?.payment_status === "Paid"
                      ? "text-green-600"
                      : "text-gray-500"
              )}
            >
              {invoices?.payment_status}<span className="ml-2">INVOICE</span>
            </span>
            <div className="flex-row items-center justify-between lg:flex">
              <div className="flex flex-col lg:my-5">
                <div>
                  <img className="h-32" alt="" src={store?.logo} />
                </div>
                <div className="flex flex-col">
                  <p className="text-2xl font-bold underline">
                    {store?.store_name}
                  </p>
                </div>
                <div className="flex">
                  <p className="font-bold">City :</p>
                  <p className="px-3">{store?.city}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">State :</p>
                  <p className="px-3">{store?.state}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Country :</p>
                  <p className="px-3">{store?.country}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Currency :</p>
                  <p className="px-3">{store?.currency}</p>
                </div>

                <div className="flex">
                  <p className="font-bold">Mob No :</p>
                  <p className="px-3">{store?.store_mobile}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">GSTIN :</p>
                  <p className="px-3">{store?.gst_number}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Area :</p>
                  <p className="px-3">{store?.store_address}</p>
                </div>
              </div>
              <div className="flex flex-col lg:my-5">
                <div className="flex">
                  <p className="text-4xl font-bold capitalize">TAX INVOICE</p>
                </div>
                <div className="flex justify-end w-full">
                  <p className="flex font-bold">{invoices?.invoice_no}</p>
                </div>
              </div>
            </div>
            <Divider />

            <div className="flex-row items-center justify-between lg:flex">
              <div className="flex flex-col lg:my-5 ">
                <div className="flex">
                  <p className="text-2xl font-bold underline">Bill To</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Company :</p>
                  <p className="px-3">{invoices?.customer?.company_name}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Customer :</p>
                  <p className="px-3">
                    {invoices?.customer?.first_name +
                      " " +
                      invoices?.customer?.last_name || ""}
                  </p>
                </div>
                <div className="flex">
                  <p className="font-bold">Area :</p>
                  <p className="px-3">{invoices?.customer?.area}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">City :</p>
                  <p className="px-3">{invoices?.customer?.city}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">State :</p>
                  <p className="px-3">{invoices?.customer?.state}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Country :</p>
                  <p className="px-3">{invoices?.customer?.nationality}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">PAN :</p>
                  <p className="px-3">{invoices?.customer?.pan_number}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">GSTIN :</p>
                  <p className="px-3">{invoices?.customer?.gst_number}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Place of Supply :</p>
                  <p className="px-3">{invoices?.customer?.source_of_supply}</p>
                </div>
              </div>
              <div className="flex flex-col my-5">
                <div className="flex">
                  <p className="font-bold">Invoice Date :</p>
                  <p className="px-3">{invoices?.date}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Terms :</p>
                  <p className="px-3">{invoices?.pay_terms}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Due Date :</p>
                  <p className="px-3">{invoices?.due_date}</p>
                </div>
                <div className="flex">
                  <p className="font-bold">Reference# :</p>
                  <p className="px-3">{invoices?.reference_no}</p>
                </div>
              </div>
            </div>
            <Divider />
            <div>
              <div className="border-2 border-black lg:my-5">
                <Table size="small">
                  <TableHead className="border-b-2 border-black">
                    <TableRow>
                      <TableCell
                        size="small"
                        sx={{ color: "black" }}
                        className="!font-bold border-r border-black"
                      >
                        #
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ color: "black" }}
                        className="!font-bold border-r border-black"
                      >
                        Items
                      </TableCell>

                      <TableCell className="!font-bold !text-black   border-r border-black ">
                        Image
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ color: "black" }}
                        className="!font-bold border-r border-black"
                      >
                        HSN/SAC
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ color: "black" }}
                        className="!font-bold border-r border-black"
                      >
                        Qnt
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ color: "black" }}
                        className="!font-bold  border-r border-black"
                      >
                        Rate
                      </TableCell>
                      {invoices?.tax === "GST" ? (
                        <>
                          <TableCell
                            sx={{ color: "black" }}
                            className="!font-bold border-r border-black"
                          >
                            SGST
                          </TableCell>
                          <TableCell
                            sx={{ color: "black" }}
                            className="!font-bold border-r border-black"
                          >
                            CGST
                          </TableCell>
                        </>
                      ) : (
                        <TableCell
                          sx={{ color: "black" }}
                          className="!font-bold border-r border-black"
                        >
                          IGST
                        </TableCell>
                      )}
                      <TableCell sx={{ color: "black" }} className="!font-bold ">
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices?.invoice_item?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ color: "black" }} className="!py-3 border-r border-black">
                          {index + 1}
                        </TableCell>
                        <TableCell sx={{ color: "black" }} className="!py-3 border-r border-black">
                          {row?.item_name}({row?.slug})
                        </TableCell>


                        <TableCell className="lg:!py-3 !text-black  border-r border-black ">

                          <img src={store?.logo} alt="storeLogo" className="h-16 w-28 " />


                        </TableCell>
                        <TableCell sx={{ color: "black" }} className="!py-3 border-r border-black">
                          {row?.hsn}
                        </TableCell>
                        <TableCell sx={{ color: "black" }}
                          className="border-r border-black"
                        >
                          {row?.quantity}
                        </TableCell>
                        <TableCell sx={{ color: "black" }}

                          className="border-r border-black"
                        >
                          {store?.currency} {row?.rate}
                        </TableCell>
                        {invoices?.tax === "GST" ? (
                          <>
                            <TableCell
                              sx={{ color: "black" }}
                              className="!py-3 border-r border-black"
                            >
                              {store?.currency} {row?.sgst ? row?.sgst : "--"}
                            </TableCell>
                            <TableCell
                              sx={{ color: "black" }}
                              className="!py-3 border-r border-black"
                            >
                              {store?.currency} {row?.cgst ? row?.cgst : "--"}
                            </TableCell>
                          </>
                        ) : (
                          <TableCell sx={{ color: "black" }} className="!py-3 border-r border-black">
                            {store?.currency}{" "}
                            {row?.igst ? row?.igst : row?.cgst + row?.sgst}
                          </TableCell>
                        )}
                        <TableCell sx={{ color: "black" }} className="!py-3 ">
                          {store?.currency} {row?.total_price}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className="flex-row items-start justify-between w-full my-5 lg:flex">
                <span className="w-1/2">
                  <p className="font-bold">Terms and Conditions :</p>
                  <p>Pay according to Terms and conditions.</p>
                </span>
                <div className="flex flex-col lg:w-[50%] w-full my-3">
                  <div className="flex flex-row items-center justify-between w-full p-2">
                    <p className="!font-bold">Total Quantity</p>
                    <p>
                      {invoices?.invoice_item?.reduce(
                        (a, b) => a + b?.quantity,
                        0
                      )}
                    </p>
                  </div>
                  <Divider className="!my-1" />
                  <div className="flex flex-row items-center justify-between w-full p-2">
                    <p className="!font-bold">Sub Total</p>
                    <p>
                      {store?.currency} {invoices?.total_price}
                    </p>
                  </div>
                  <Divider className="!my-1" />

                  {invoices?.tax === "IGST" ? (
                    <div className="flex flex-row items-center justify-between w-full p-2">
                      <p className="!font-bold">IGST</p>
                      <p>
                        {store?.currency}{" "}
                        {invoices?.igst
                          ? invoices?.igst
                          : invoices?.cgst + invoices?.sgst}
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-row items-center justify-between w-full p-2">
                        <p className="!font-bold">CGST</p>
                        <p>
                          {store?.currency} {invoices?.cgst}
                        </p>
                      </div>
                      <Divider className="!my-1" />
                      <div className="flex flex-row items-center justify-between w-full p-2">
                        <p className="!font-bold">SGST</p>
                        <p>
                          {store?.currency} {invoices?.sgst}
                        </p>
                      </div>
                    </>
                  )}
                  <Divider className="!my-1" />
                  <div className="flex flex-row items-center justify-between w-full p-2">
                    <p className="font-bold">Total Amount</p>
                    <p>
                      {store?.currency} {invoices?.total_of_discount_plus_ship}
                    </p>
                  </div>
                  <Divider className="!my-1" />
                  {invoices?.payment_amount?.map((paid) => {
                    return (
                      <>
                        <div className="flex flex-row items-center justify-between w-full p-2">
                          <p className="font-bold">Paid</p>
                          <p>
                            {store?.currency}{" "}
                            <span className="text-red-500">{paid}</span>
                          </p>
                        </div>
                        <Divider className="!my-1" />
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-row bg-[#F5F4F3] items-center justify-end w-full p-2">
                <span className="flex justify-between w-1/2">
                  <p className="font-bold">Balance Due</p>
                  <p>
                    {store?.currency} {invoices?.remaining_amount}
                  </p>
                </span>
              </div>
              <Divider />
              <div className="flex-row items-center justify-between w-full my-5 lg:flex">
                <div className="flex flex-col w-full">
                  <p className="font-bold">Authorised Signature</p>
                  <img src={store?.signature} alt="" className="w-32 h-32" />
                </div>
                <div className="flex flex-col justify-center w-full">
                  <p className="font-bold">Scan UPI for payment</p>
                  <img
                    src={store?.upi_code}
                    alt=""
                    className="w-32 h-32 p-2 border"
                  />
                </div>
                <div className="grid w-full grid-cols-1 lg:items-end lg:my-0">
                  <p className="my-2 font-bold">
                    Bank Details are Given Below :
                  </p>
                  <div className="flex flex-row my-2">
                    <p className="font-bold">Bank name :</p>
                    <p className="px-2">{store?.bank_name}</p>
                  </div>
                  <div className="flex flex-row my-2">
                    <p className="font-bold">Bank A/c. No. :</p>
                    <p className="px-2">{store?.account_no}</p>
                  </div>
                  <div className="flex flex-row my-2">
                    <p className="font-bold">IFSC Code :</p>
                    <p className="px-2">{store?.IFSC}</p>
                  </div>
                  <div className="flex flex-row my-2">
                    <p className="font-bold">Swift Code :</p>
                    <p className="px-2">{store?.swift_code}</p>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="flex flex-col lg:w-[50%] py-2 w-full">
                <p className="font-bold">Note : </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: invoices?.description,
                  }}
                  className="whitespace-break-spaces"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

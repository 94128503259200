import axios from "axios";
import { baseURL } from "../../Config/BaseUrl";
import { API_URLS } from "../../Config/apiUrls";

export const CustomerLedger = (s_id, c_id, dateFilters) => {
    console.log("jdksugsdjkdgk", s_id, c_id, dateFilters);
    try {
        const response = axios.get(
            baseURL + API_URLS.customer_ledger_for_customer_panel_api,
            {
                params: dateFilters
                    ? {
                        filter_date: dateFilters,
                        customer_id: c_id,
                        store_id: s_id,
                    }
                    : {
                        customer_id: c_id,
                        store_id: s_id,
                    },

                headers: {
                    Authorization: localStorage.getItem("customersToken"),
                },
            }
        );

        return response || [];
    } catch (e) {
        console.log(e);
    }
};

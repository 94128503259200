import VerifiedIcon from "@mui/icons-material/Verified"
import { FormHelperText, MenuItem } from "@mui/material"
import { API_URLS } from "../../../Config/apiUrls"
import axiosInstance from "../../../Config/axio.config"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import CustomButton from "../../CustomButton"
import CustomModal from "../../CustomModal"
import { TwoFactorSchema } from "./TwoFactorSchema"

const TwoFactorAnable = ({ twoStep, handleClose1 }) => {
  const [open, setOpen] = React.useState(false)
  const [qrCode, setQrCode] = useState({})
  const handleClickOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const initialValue = { otp: "" }

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: TwoFactorSchema,
    onSubmit: (values) => {
      const fd = new FormData()
      fd.append("otp", values.otp)
      fd.append("auth_key", twoStep === "Enable" ? "Disable" : "Enable")
      axiosInstance.post(API_URLS.enableTwoStep, fd).then((res) => {
        toast.success(res.data.message)
        if (res.data.response_code === 200) {
          handleClose()
          formik.resetForm("")
          handleClose1()
          localStorage.setItem("two_step", res.data.auth_key)
        }
      })
    },
  })

  const getQrCode = () => {
    axiosInstance.get(API_URLS.enableTwoStep).then((response) => {
      setQrCode(response.data)
    })
  }
  useEffect(() => {
    getQrCode()
  }, [])

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        <span className="flex gap-2 cursor-pointer hover:bg-white hover:bg-opacity-50 hover:backdrop-blur-mk hover:rounded">
          <VerifiedIcon className="" /> Two Factor ({twoStep || "Disabled"})
        </span>
      </MenuItem>
      <CustomModal
        title={`Two Step ${" "}(${twoStep ?? ""})`}
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        className={"!w-[500px]"}
      >
        <div className=" !flex !flex-col mx-auto !gap-y-3">
          <img src={qrCode?.qcode} alt="No" className="mx-auto shadow-md h-60 shadow-violet-500" />
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col items-center w-full mx-auto">
              <input
                type="number"
                id="otp"
                name="otp"
                minLength={6}
                maxLength={6}
                value={formik.values["otp"]}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                defaultValue=""
                placeholder="Enter Your OTP"
                className="focus:outline-none border bg-slate-100 w-60 !mx-auto p-2 border-purple-200 rounded tracking-wider"
              />
              <FormHelperText className="!text-red-700 pb-3  !ml-24 !mx-auto">{formik.errors["otp"]}</FormHelperText>
            </div>
            <div className="flex justify-center mx-auto">
              <CustomButton type={"submit"}>Verify</CustomButton>
            </div>
          </form>
        </div>
      </CustomModal>
    </>
  )
}

export default TwoFactorAnable

import React from "react";
import CustomDiv from "../Shared/CustomDiv";
import Header from "../Shared/Header";
import Footer from "../Shared/footer";

const Layout = ({ component, setTheme, theme }) => {
  return (
    <CustomDiv
      elevation={0}
      className="h-screen overflow-y-auto w-full !rounded-none"
    >
      <Header setTheme={setTheme} theme={theme} />
      {/* <CustomDiv elevation={0} className="h-body !rounded-none">
        {component}
      </CustomDiv> */}
      {/*  <Footer setTheme={setTheme} theme={theme} className="" /> */}
    </CustomDiv>
  );
};

export default Layout;

import { API_URLS } from "../../Config/apiUrls"
import axiosInstance from "../../Config/axio.config"

export const storeProfileFn = (redBody) => {
  try {
    const response = axiosInstance.get(API_URLS.storeProfile, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const updateProfileFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.storeProfile, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const submitNewDomain = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.domainName, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

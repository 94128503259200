export const API_URLS = {
    storeSettings: "general-settings-api-of-bhaaraterp/",
    counterApi: "cash-counter-api/",
    storePermition: "employee_list_with_permission_with_role_with_department__bhaaraterp/",
    storeRequest: "store-request-for-inventory-data-api-of-bhaaraterp/",
    login: "Api_data/user-login-with-password-api-of-bhaaraterp/",
    verifyOtp: "verify-totp-for-user-api-of-bhaaraterp/",
    sendOtp: "hrm/user-forgot-password-api-of-bhaaraterp/",
    enableTwoStep: "two-factor-authentication-enable-or-disable-of-bhaaraterp/",
    twoStepVerification: "generate-totp-for-user-api-of-bhaaraterp/",
    signup: "Api_data/api/login/",
    variantTypeOne: "type-one-list-of-category-api-of-bhaaraterp/",
    variantTypeTwo: "/type-two-list-of-category-api-of-bhaaraterp/",
    variantTypeThree: "/type-three-list-of-category-api-of-bhaaraterp/",
    storeFileList: "store-list-file-data/",
    storeFolderList: "store-list-folder-data/",
    createfolder: "store-add-folder-document/",
    uploadFile: "store-add-file-document/",
    deleteSelectedFile: "store-bulk-file-delete/",
    renameFolder: "store-update-folder-data/",
    categoryList: "product/category-sub-category-list-api-of-bhaaraterp/",
    storeCategoryList: "product/store-category-api-of-bhaaraterp/",
    renameFile: "store-update-file-data/",
    starredFileList: "store-list-favourite-file-data/",
    addFileToFavourite: "store-add-file-to-favourite/",
    addProductByExcel: "product/add-product-by-excel-api-of-bhaaraterp/",
    addInventoryByExcel: "product/inventory-upload-from-excel-api-of-bhaaraterp/",
    requestInventoryByExcel: "store-request-for-inventory-data-by-excel-api-of-bhaaraterp/",
    addProductWithVariantByExcelNew: "set-product-excel-data-for-celery-api-of-bhaaraterp/",
    addCustomerByExcel: "set-excel-data-for-celery-api-of-bhaaraterp/",
    updateCustomerByExcel: "set-update-excel-data-for-celery-api-of-bhaaraterp/",
    addProductWithVariantByExcel: "product/add-product-with-variant-by-excel-api-of-bhaaraterp/",
    dashboardData: "report/erp-dashboard-list-get/",
    addCustomers: "sales/add-customer-api-of-bhaaraterp/",
    addCustomersListing: "sales/customer-data-list-api-of-bhaaraterp/",
    addVendorsListing: "/purchase/add-vendor-api-of-bhaaraterp/",
    addSalesOrders: "sales/add-sales-order-api-of-bhaaraterp/",
    orderlist: "api/users/order_list",
    purchaseProductData: "product/product-data-api-of-bhaaraterp/",
    achivaDataList: "leads/approval-target-list-api-of-bhaaraterp/",
    addSalesInvoice: "sales/add-sales-invoice-api-of-bhaaraterp/",
    addServices: "sales/add-service-api-of-bhaaraterp/",
    deleteSalesOrder: "sales/delete-sales-order-api-of-bhaaraterp/",
    addpurchaseInvoice: "purchase/add-purchase-invoice-api-of-bhaaraterp/",
    purchaseInvoiceList: "purchase/purchase-invoice-list-api-of-bhaaraterp/",
    deletePurchaseInvoice: "purchase/delete-purchase-invoice-api-of-bhaaraterp/",
    getPurchaseInvoicePdf: "purchase/purchase-invoice-list-api-of-bhaaraterp/",
    purchaseOrderList: "purchase/purchase-order-list-api-of-bhaaraterp",
    addPurchaseOrder: "purchase/add-purchase-order-api-of-bhaaraterp/",
    deletePurchaseOrder: "purchase/delete-purchase-order-api-of-bhaaraterp/",
    getPurchaseOrderPdf: "purchase/purchase-order-pdf-url-of-bhaaraterp/",
    countryStateCity: "country-state-city-list-api-of-bhaaraterp/",
    vendorList: "purchase/vendor-list-api-of-bhaaraterp/",
    changeReturnStatus: "purchase/return-purchase-order-api-of-bhaaraterp/",
    changeReturnProducts: "purchase/vendor-purchase-product-api-of-bhaaraterp/",
    addVendor: "purchase/add-vendor-api-of-bhaaraterp/",
    deleteVendor: "purchase/delete-vendor-api-of-bhaaraterp/",
    updatevendor: "purchase/vendor-list-api-of-bhaaraterp/",
    debitList: "purchase/debit-voucher-api-of-bhaaraterp/",
    creditVoucherList: "sales/credit-voucher-api-of-bhaaraterp/",
    payment: "purchase/payment_record-api-of-bhaaraterp/",
    ledgerList: "purchase/account_list_bhaaraterp/",
    expenseList: "purchase/expense-list-api-of-bhaaraterp/",
    addExpense: "purchase/add-expense-api-of-bhaaraterp/",
    deleteExpense: "purchase/delete-expense-api-of-bhaaraterp/",
    setScheduler: "purchase/set-scheduler-api-recurring-expense-for-bhaaraterp/",
    customerList: "sales/customer-list-with-tax-details-api-of-bhaaraterp/",
    inventoryCounters: "product/product-dashboard-section-counter-api-of-bhaaraterp/",
    specsCategoryWiseTypes: "product/add-product-specification-type-api-of-bhaaraterp/",
    specsCategoryTypes: "product/product-specification-type-api-of-bhaaraterp/",
    specifications: "product/product-specification-api-of-bhaaraterp/",
    specificationData: "product/specification-data-api-of-bhaaraterp/",
    brands: "product/brand-list-api-of-bhaaraterp/",
    stockVerifyProducts: "product/item-for-stock-verification-api-of-bhaaraterp/",
    newInventoryVerify: "product/stock-verification-api-of-bhaaraterp/",
    inventoryVerifyDetail: "product/stock-verification-item-details-api-of-bhaaraterp/",
    shortProductUpdate: "product/update-product-variant-quantity-price-api-of-bhaaraterp/",
    transferVariant: "product/product-transfer-to-child-warehouse-api-of-bhaaraterp/",
    enableDisableProduct: "product/product-variant-disable-enable-api-of-bhaaraterp/",
    syncForEnable: "product/product-sync-disable-enable-api-of-bhaaraterp/",
    variantListByProductId: "product/copy-product-variant-specification-api-of-bhaaraterp/",
    estimatesList: "sales/estimate-list-api-of-bhaaraterp/",
    addEstimates: "sales/add-estimate-api-of-bhaaraterp/",
    checkoutDiscount: "product/check-product-offer-api-of-bhaaraterp/",
    salesProductList: "sales/sales-order-list-api-of-bhaaraterp/",
    approveEstimates: "sales/approve-estimate-api-of-bhaaraterp/",
    variantsList: "product/product-variant-list-api-of-bhaaraterp/",
    productWithVariants: "product/product-with-variant-list-api-of-bhaaraterp/",
    productList: "product/product-list-api-of-bhaaraterp/",
    batch: "product/batch-number-data-api-of-bhaaraterp/",
    productSearch: "product/product-variant-search-api-of-bhaaraterp/",
    variantSelectionImage: "product/copy-product-variant-image-api/",
    productWithVariantSearch: "product/product-with-variant-search-api-of-bhaaraterp/",
    productSearchByBarcode: "product/product-variant-barcode-search-api-of-bhaaraterp/",
    deleteProductFromWeb: "product/delete-product-data-from-woocommerce-website/",
    deleteAllProduct: "product/delete-all-product-api-of-bhaaraterp/",
    AddServiceType: "support_page/add-service-type-api-of-bhaaraterp/",
    listServiceType: "support_page/service-type-list-api-of-bhaaraterp/",
    updateServiceType: "support_page/edit-service-type-api-of-bhaaraterp/",
    addSupportService: "support_page/add-service-api-of-bhaaraterp/",
    priorityList: "support_page/priority-type-api-of-bhaaraterp/",
    questionService: "support_page/service-question-api/",
    questionnaire: "support_page/questionnaire-api/",
    singleQuestionnaire: "support_page/questionnaire-detail-api/",
    mapServiceToQuestionnaire: "support_page/service-map-to-questionnaire-api/",
    customer_search: "sales/customer-list-api-of-bhaaraterp/",
    invoice_list: "sales/sales-invoice-list-api-of-bhaaraterp/",
    approve_invoice: "sales/approve-sales-invoice-api-of-bhaaraterp/",
    make_sales_invoice_payment: "sales/payment-of-sale-invoice-api-of-bhaaraterp/",
    add_delivery_challan: "sales/add-delivery-challan-api-of-bhaaraterp/",
    delivery_challan_list: "sales/delivery-challan-list-api-of-bhaaraterp/",
    get_sales_order_list: "sales/sales-order-list-api-of-bhaaraterp/",
    is_enable_image: "sales/image-enable-disable-api-of-bhaaraterp/",
    addwhatsapp: "/whatsapp-api-configuration-for-admin/",
    get_received_payment_list: "sales/payment-recieved-list-api-of-bhaaraterp/",
    get_subinventory_data: "/product/sub-inventory-list/",
    subWarehouseProduct: "product/warehouse-product-api-of-bhaaraterp/",
    requestInventory: "product/inventory-request-from-sub-inventory/",
    requestInventoryWarehouses: "product/product-request-to-warehouse-api-of-bhaaraterp/",
    approve_status: "/product/inventory-enable-or-disable/",
    get_warehouse_inventory: "/product/inventory-request-list/",
    convertToSalesOrder: "sales/convert-estimate-to-sales-order-api-of-bhaaraterp/",
    get_inventory_history_list: "/product/inventory-history-api/",
    revert_status_request_warehouse_inventory: "/product/inventory-request-accept-or-decline/",
    reportCustomerMaster: "report/customer-master-data-list-api-of-bhaaraterp/",
    vendorMasterReport: "report/vendor-master-api-of-bhaaraterp/",

    addRole: "store-role-api-of-bhaaraterp/",
    roleList: "store-role-api-of-bhaaraterp/",
    countryList: "leads/api/lead-country-list-api/",
    stateList: "leads/api/lead-state-list-api/",
    cityList: "leads/api/lead-city-list-api/",
    salesPersonList: "leads/api/sales-persons-list-in-leads/",
    lead_types_list_api: "leads/api/leads-source-customer-industry-api/",
    addDepartment: "store-department-api-of-bhaaraterp/",
    updateDepartment: "store-department-api-of-bhaaraterp/",

    credit_notes_list: "sales/credit-notes-bhaaraterp/",
    reports: { inventoryAginglist: "report/inventory_aging_summary_api/" },
    bgImage: "store-theme-settings-for-store-admin/",
    domainName: "website-request-api-of-admin/",
    storeProfile: "store-profile-details-api-of-bhaaraterp/",
    setting: {
        plan: "api_show_store_purchase_plan_bhaaraterp/",
        inventory: "api_general_settings_bhaaraterp_bhaaraterp/",
        addgst: "api_store_gst_and_online_filling_settings_bhaaraterp/",
        addgeneral: "api_general_settings_bhaaraterp_bhaaraterp/",
        allSettings: "api_general_settings_bhaaraterp_bhaaraterp/",
    },
    customer_list_graph: "sales/customer-income-graph-api-of-bhaaraterp/",
    get_add_e_way_bill: "sales/e-way-bhaaraterp/",
    bill_invoice: "sales/bill-invoice-list-api-of-bhaaraterp/",
    otpVerify: "Api_data/user-login-with-otp-api-of-bhaaraterp/",
    background: "store-set-theme-settings-for-store-admin/",
    leaveApplyStatus: "hrm/leave-application-request-approval-api-of-bhaaraterp/",

    getHiringCandidate: "hrm/candidate_list_api/?page=1",
    addCondidate: "hrm/create_candidate_api/",
    deleteCond: "hrm/deleteCandidate_api/",
    payRollList: "hrm/payroll_view_api/",
    givePermisson: "hrm/give_permission_api/",
    reportsalesorderregister: "report/sales-order-register-report-api-of-bhaaraterp/",
    contrectalInterviewer: "hrm/interviewer-contract-employee-list-api-of-bhaaraterp/",
    contractInterview: "hrm/delete-contract-employee-api-of-bhaaraterp/",
    dashbaord: { counts: "report/erp-dashboard-list-get/" },
    chatsList: "cronjob/whatsapp-message-list-api/",
    messages: "cronjob/reply-whatsapp-message-api/",
    getProfileDetails: "leads/api/lead-profile-update-data/",
    updateProfileDetails: "leads/api/lead-profile-update-data/",
    chatProfile: "cronjob/whatsapp-profile-details-api/",
    enableTemplate: "cronjob/whatsapp-template-automation-enable-api/",
    whatsappTemplates: "cronjob/whatsapp-template-list-api/",
    whatsappTemplates2: "cronjob/whatsapp-template-list-without-mobile-number-api/",
    whatsAppContacts: "cronjob/whatsapp-contact-list-api/",
    whatsAppChatMenuList: "cronjob/store-whatsapp-bussiness-account-list/",
    createTemplate: "cronjob/whatsapp-template-list-api/",
    updateTemplate: "cronjob/whatsapp-template-list-api/",
    createButton: "cronjob/whatsapp-template-button-api/",
    createSection: "cronjob/whatsapp-message-section-api/",
    createSectionRow: "cronjob/whatsapp-message-section-rows-api/",
    LabalList: "cronjob/whatsapp-labels/",
    createLabal: "cronjob/whatsapp-labels/",
    AddUserLabal: "cronjob/labels-for-whatsapp-user/",
    RemoveUserLabal: "cronjob/labels-for-whatsapp-user/",
    upDateLabal: "cronjob/whatsapp-labels/",
    upDateButton: "cronjob/whatsapp-template-button-api/",
    upDateList: "cronjob/whatsapp-message-section-rows-api/",
    whatsappWorkflow: "cronjob/whatsapp-workflow/",
    workflowSteps: "cronjob/whatsapp-workflow-step/",
    customAutoReply: "cronjob/whatsapp-custom-auto-reply/",
    deleteButton: "cronjob/whatsapp-template-button-api/",
    deleteSection: "cronjob/whatsapp-message-section-api/",
    deleteSectionRow: "cronjob/whatsapp-message-section-rows-api/",
    deleteVariations: "cronjob/whatsapp-customer-input-variation/",
    variableList: "cronjob/whatsapp-customer-input-variable/",
    updateVariablesInTemplate: "cronjob/whatsapp-template-variable-api/",
    notificationBellList: "notification/notification-list/",
    notificationBellSeenData: "notification/notification-list/",
    generateAccessToken: "cronjob/generate-access-token/",
    sendDefaultTemplate: "cronjob/send-whatsapp-message-template/",
    updateTemplateSection: "cronjob/whatsapp-section-list-and-row-list-api/",
    updateTemplateButton: "cronjob/whatsapp-button-list-api/",
    templateApproval: "cronjob/manage-whatsApp-fixed-template-by-store-admin-api/",
    settings: {
        chat: {
            storeWorkingHourList: "cronjob/store-working-hour/",
            addStoreWorkingHour: "cronjob/store-working-hour/",
            updateStoreWorkingHour: "cronjob/store-working-hour/",
            deleteStoreWorkingHour: "cronjob/store-working-hour/",
            erpTemplateList: "cronjob/whatsapp-template-list-without-mobile-number-api/",
        },
    },
    sms: "store_sms_configuration_store_api_of_bhaaraterp/",
    addSms: "store_sms_configuration_store_api_of_bhaaraterp/",
    updateemails: "email-setting-configuration-list-api-of-bhaaraterp/",
    emails: "email-setting-configuration-list-api-of-bhaaraterp/",
    addEmails: "add-email-setting-configuration-api-of-bhaaraterp/",
    emailTemplateList: "email-template-setting-api-of-bhaaraterp/",
    templateType: "email-template-type-api-of-bhaaraterp/",
    paymentlist: "store-payment-method-api-of-bhaaraterp/",
    emiPayment: "emipayment-api/",
    payLaterPayment: "paylater-bhaaraterp-api/",
    enableDisableEMIs: "emi-plan-active-deactive-api/",
    enableDisablePayLater: "paylater-active-deactive-api/",
    addPayment: "store-payment-method-api-of-bhaaraterp/",
    updatePayment: "store-payment-method-api-of-bhaaraterp/",
    confirmEmails: "confirm-setting-email-setting-configuration-api-of-bhaaraterp/",
    campaigns: "leads/campaigns-list-api-of-bhaaraterp/",
    createCampaigns: "leads/add-email-campaigns-api-of-bhaaraterp/",
    campiagnsOptions: "leads/data-list-api-for-campaigns-of-bhaaraterp/",
    campaignsMarketing: "leads/campaigns-marketing-api/",
    campaignDetail: "leads/campaigns-details-api-of-bhaaraterp/",
    campaignsTemplates: "leads/all-campaigns-template-api-of-bhaaraterp/",
    deleteTemplate: "leads/disable-campaign-template-api-of-bhaaraterp/",
    deleteCampaigns: "leads/delete-campaigns-api-of-bhaaraterp/",
    numbers: "sales/add-set-number-api-of-bhaaraterp/",
    updateNumbers: "sales/update-set-number-api-of-bhaaraterp/",
    changeNumberStatus: "sales/change-manual-or-auto-number-api-of-bhaaraterp/",
    activityLogs: "inventory_history/view-log-history-bhaarat-erp/",
    manageRms: "sales/return-replace-request-list-api/",
    returnCustomersList: "sales/customer-list-api-of-return-replace-request/",
    returnReplacementResons: "sales/return-replace-reason-list-api/",
    rmsOrdersList: "sales/customer-order-list-api/",
    rmsOrderItems: "sales/order-item-list-api/",
    rmsRequestItems: "sales/request-item-list-api/",
    addReturnAndRequest: "sales/add-return-replace-request-api/",
    addReturnFn: "sales/sales-order-return-request-api/",
    customerDetails: "sales/customer-overview-bhaaraterp/",
    financeDashbaord: "account/api/finance-dashboard-api-of-bhaaraterp/",
    changeStatusRms: "sales/change-request-status-api/",
    labels: { customerType: "sales/store-labels-data-api-of-bhaaraterp/" },

    cashFlow: "report/cash-flow-report-api-of-bhaaraterp/",
    profitAndLossList: "report/profit-loss-report-api-of-bhaaraterp/",
    balanceSheetList: "report/balance-sheet-report-api-of-bhaaraterp/",
    bankReport: "report/banking-report-api-of-bhaaraterp/",
    bankReportData: "report/banking-report-data-api-of-bhaaraterp/",
    addChildWarehouse: "product/add-child-warehouse-of-bhaaraterp/",
    updateChildWarehouse: "product/edit-child-warehouse-of-bhaaraterp/",
    purchasePayment: "purchase/payment-of-purchase-invoice-api-of-bhaaraterp/",
    accountListPurchase: "purchase/account-list-for-payment-of-purchase-invoice-api-of-bhaaraterp/",
    storeAssets: "store-assets-api-of-bhaaraterp/",
    whatsAppReport: "report/whatsapp_list_report_api/",
    listWhatsAppBusiness: "cronjob/list-whatsapp-bussiness/",
    dueList: "/dashboard_graph/customer-due-amount-api/",
    vendorDueList: "/dashboard_graph/vendor-due-amount-api/",
    listWhatsAppProfile: "cronjob/list-whatsapp-profile/",
    store: {
        role: "store-role-api-of-bhaaraterp/",
        department: "store-department-api-of-bhaaraterp/",
        operations: "project/production-operations/",
        details: "store-details-api-of-bhaaraterp/",
    },
    serviceHistory: "sales/service-list-api-of-bhaaraterp/",
    ServiceApi: "hrm/add-service-to-employee-api-of-bhaaraterp/",
    leadHiestryApi: "support_page/user-buy-plan-history-api-of-bhaaraterp/",
    planList: "support_page/plan-ticket-list-api-of-bhaaraterp/",
    plnaEmpList: "support_page/buy-ticket-plan-api-of-bhaaraterp/",
    bayplnaEmpList: "support_page/user-ticket-list-api-of-bhaaraterp/",
    addPlan: "support_page/add-ticket-plan-api-of-bhaaraterp/",
    delete: "support_page/delete-ticket-plan-api-of-bhaaraterp/",
    ticketDetails: "support_page/ticket-details-api/",
    vendorInvoiceData: "purchase/vendor-invoice-data-api-of-bhaaraterp/",
    customerInvoiceData: "sales/customer-invoice-data-api-of-bhaaraterp/",
    assignInventory: "product/assign-inventory-to-manager-api/",
    assignStore: "product/assign-inventory-to-store-manager-api/",
    salesGraph: "sales/sales_dashboard_report_relation_with_estimate_sales_pos_invoice_order_api/",
    purchaseGraph: "purchase/purchase_dashboard_report_relation_with_purchase_invoice_order_expanse_api/",
    financeGraph: "ledger/finance_dashboard_report_recivable_and_payable_api/",
    crmGraph: "leads/leads_dashboard_report_relation_with_leads_vs_deals_api/",
    hrmGraph: "hrm/hrm_dashboard_report_relation_with_candidate_totall_vs_candiate_hired_api/",
    supportGraph: "support_page/support_dashboard_report_relation_with_services_ticket_vs_services_booked_api/",
    clientSide: "support_page/customer-site-image-collection-api/",


    buisnessgraph: "dashboard_graph/payable-receiable/",
    TopSalegraph: "dashboard_graph/top_10_sales_by_sales_person/",
    TopCustomer: "dashboard_graph/top_10_sales_by_customer_type/",
    purchaseCategory: "dashboard_graph/top_10_purchase_by_category/",
    salesCategory: "dashboard_graph/top_10_sales_by_category/",
    salesByRegion: "dashboard_graph/top_10_sales_by_region/",
    purchaseByRegion: "dashboard_graph/top_10_purchase_by_region/",

    notification: "store_push_notification_setting_configuration_api_of_bhaaraterp/",
    pushNotification: "store-push-notification-setting-api-of-bhaaraterp/",
    customerType: "leads/api/lead-customer-type-api/",
    leadSource: "leads/api/lead-source-api/",
    leadHashtag: "store-hashatg-bhaaraterp-api/",
    geo_fencing: "store-geofence-bhaaraterp-api/",
    holidays_list: "store-holiday-calender-bhaaraterp-api/",
    industries: "leads/api/lead-industry-type-api/",
    changeLeadTypesStatus: "leads/api/leads-enable-disable/",
    googleMapConfigs: "store-google-map-setting-api-of-bhaaraterp/",
    notifications: "notification/store-notification-api-of-bhaaraterp/",
    incentive: "incentive-plan-operations-api-bhaaraterp-api/",
    status_Incentive: "store_incentive_plan_enable_disable_bhaaraterp-api/",
    changeConfigStatus: "store-push-notification-acitive-or-deactive-api-of-bhaaraterp/",
    woocommerceSync: "wocommerce-api-configuration-for-admin/",

    rackList: "product/rack-data-api-of-bhaaraterp/",
    mesuringList: "product/measuring-unit-api-of-bhaaraterp/",
    changeContactOwner: "leads/change-sales-persons-of-contact/",
    changeLeadsOwner: "leads/change-lead-owner-of-lead/",
    addLeadByExcel: "leads/add-lead-by-excel-api-of-bhaaraterp/",
    changeLeadStatus: "leads/api/lead_data_patch/",
    enableOrDisableEmployee: "hrm/employee-activate-and-deactivate-api-of-bhaaraterp/",
    warehouseLocation: "product/warehouse-location-api-of-bhaaraterp/",
    offerManagment: "product/offer-management-api-of-bhaaraterp/",
    offerData: "product/category-product-service-data-api-of-bhaaraterp/",
    enableDisableOffer: "product/offer-enable-or-disable-api-of-bhaaraterp/",
    enableInventoryItem: "inventory_data_api_sync_enable_disble_of_bhaaraterp/",
    inventoryItems: "product/inventory-data-api-of-bhaaraterp/",
    categoryItems: "product/category-api-of-bhaaraterp/",
    subCategoryItems: "product/subcategory-api-of-bhaaraterp/",
    brandItems: "product/brand-api-of-bhaaraterp/",
    typeOne: "product/type-one-variant-api/",
    typeTwo: "product/type-two-variant-api/",
    typeThree: "product/type-three-variant-api/",
    typeOneValue: "product/type-one-variant-value-api/",
    typeTwoValue: "product/type-two-variant-value-api/",
    typeThreeValue: "product/type-three-variant-value-api/",
    addVariantExcel: "product/variant-excel-api-of-bhaaraterp/",
    addVariantValueExcel: "product/variant-value-excel-api-of-bhaaraterp/",
    delievrySettings: "delivery-setting-api-of-bhaaratep/",
    shipRocketConfig: "shiprocket-setup-api-of-bhaaratep/",
    shipRocketStatus: "shiprocket-active-or-deactive-api-of-bhaaratep/",
    newsAndAnnouncement: "news-announcement-api-of-bhaaraterp/",
    paymentGateway: "store-payment-gateway-api-of-bhaaraterp/",
    paymentCredential: "store-payment-gateway-credential-api-of-bhaaraterp/",
    posOrderRegister: "report/pos-sales-report-api-of-bhaaraterp/",
    paymentOptions: "payment-gateway-api-of-bhaaraterp/",
    vehicle_transport: "vehicle-transport-bhaaraterp-api/",
    vehicle_status_change: "vehicle-transport-activate-deactivate/",
    courier_status_change: "courier-transport-activate-deactivate/",
    courier_transport: "courier-transport-bhaaraterp-api/",

    pos: {
        dashboard: "sales/dashboard-api-of-bhaaratpos/",
        itemsList: "sales/item-data-api-of-bhaaratpos/",
        orderList: "sales/pos-order-api-of-bhaaratpos/",
        editOrder: "sales/edit-pos-order-api-of-bhaaratpos/",
        deleteOrder: "sales/pos-order-invoice-delete-api-of-bhaaratpos/",
        lastOrder: "sales/last-pos-bill-api-of-bhaaratpos/",
        searchOrder: "sales/pos-order-invoice-api-of-bhaaratpos/",
        customers: "sales/customer-data-api-of-bhaaratpos/",
        customersList: "sales/customer-api-of-bhaaratpos/",
        holdCart: "sales/pos-cart-api-of-bhaaratpos/",
        countryStateCity: "country-state-city-list-api-of-bhaaraterp/",
        createCustomer: "sales/add-customer-api-of-bhaaratpos/",
        additionalCharge: "sales/additional-charges-api-of-bhaaratpos/",
        paymentMethods: "sales/pos-payment-mode-api-of-bhaaratpos/",
        paymentList: "sales/pos-payment-receipt-api-of-bhaaratpos/",
        creditNotesList: "sales/pos-credit-note-api-of-bhaaratpos/",
        returnRefund: "sales/pos-return-refund-order-api-of-bhaaratpos/",
        posSetting: "sales/pos-bill-print-setting-api-of-bhaaratpos/",
        coupons: "sales/coupon-offer-api-of-bhaaratpos/",
        cashControl: "sales/cash-control-api-of-bhaaratpos/",
        serviceHistory: "support_page/book-service-list-api-of-bhaaraterp/",
        batchCheck: "product/product-price-based-on-batch-number-api-of-bhaaraterp/",
        paymentMethodsByType: "sales/pos-payment-account-for-single-payment-api-of-bhaaratpos/",
        loyality: "sales/customer-loyalty-account-api-of-bhaaraterp/",
        websiteOrders: "sales/sales-order-from-website-api-of-bhaaraterp/",
        floorTables: "restaurant/floor-table-api-of-bhaaraterp/",
        tableOrders: "restaurant/table-order-api-of-bhaaraterp/",
        kotPayment: "restaurant/collect-kot-payment-api-of-bhaaraterp/",
        kotViews: "restaurant/kot-api-of-bhaaraterp/",
        questionnaireDetails: "support_page/book-service-api/",
        questionnaireFromBookingId: "support_page/booking-service-answer-api/",
        bookService: "support_page/book-service-api/",
        changeQuestionOrder: "support_page/manage-question-sequence-api/",
        checkCounterAssigned: "/sales/cash-counter-api-of-bhaaratpos/",
        counterAssignment: "/sales/cash-counter-data-api-bhaaratpos/",
        closeCounter: "/sales/cash-counter-api-of-bhaaratpos/",
        openingBalance: "/sales/cash-control-api-of-bhaaratpos/",
        productInvoiceDetails: "sales/pos-invoice-details-api-of-bhaaratpos/",
        rewardList: "/sales/customer-loyalty-points-of-reelo-api/",
        sendRedemptionOtp: "/sales/send-redemption-otp-api/",
        verifyRedemptionOtp: "/sales/reward-redemption-otp-verification-api/",
        shareCard: "/sales/loyalty-card-send-to-customer-api/",
        postEmiTerms: "apply-emipayment-api/",
    },
    createTable: "restaurant-table-data-api-of-bhaaraterp/",
    loyalityPointSetting: "store-loyalty-point-setup-api-of-bhaaraterp/",
    loyalityPointStatus: "store-loyalty-point-acitive-or-deactive-api-of-bhaaraterp/",
    lowStockGraph: "dashboard_graph/inventory-stock-graph-api-of-bhaaraterp/",
    expiryDateGraph: "dashboard_graph/inventory-expiring-graph-api-of-bhaaraterp/",
    incomeGraph: "purchase/vendor-income-graph-api-of-bhaaraterp/",
    allGstTransaction: "account/api/gstr-1-transaction-api-of-bhaaraterp/",
    restaurant: {
        floors: "restaurant/restaurant-floor-api-of-bhaaraterp/",
        tables: "restaurant/restaurant-table-api-of-bhaaraterp/",
    },
    reeloStatus: "reelo-account-api/",
    payLater: "paylater-list-bhaaraterp_api/",
    customer_ledger_for_customer_panel_api: "/report/customer-ledger-for-customer-panel-api/",
    get_customer_login_otp_api: "Api_data/get-customer-login-otp-api/"
}

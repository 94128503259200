import { Download } from "@mui/icons-material"
import { Chip, CircularProgress } from "@mui/material"

// import { useStore } from "Settings"


import axios from "axios"
import moment from "moment"
import { useSnackbar } from "notistack"
import { useCallback } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { Margin, usePDF } from "react-to-pdf"
import { baseURL } from "../../Config/BaseUrl";
import CustomButton from "../../Shared/CustomButton"


export default function ViewInvoice() {
  const { pdf: invoice_id, storeId } = useParams();
  // const { store } = useStore()



  const { enqueueSnackbar } = useSnackbar();
  const invoicesListFn = useCallback(() => {
    try {
      const response = axios.get(
        `${baseURL}/customer-pannel/customer-invoice-details-api-in-bhaaraterp/?page=1&store_id=${storeId}&invoice_id=${invoice_id}`,
        {
          headers: {
            authorization: localStorage.getItem("customersToken"),
          },
        }
      );

      return response;
    } catch (error) {
      enqueueSnackbar(error.message, {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
    }
  }, [enqueueSnackbar, invoice_id, storeId]);

  const { data: invoiceById, isLoading } = useQuery(
    ["invoice", invoice_id],
    () => invoicesListFn({ invoice_id: invoice_id }),
    { enabled: Number(invoice_id) ? true : false }
  )
  const invoices = invoiceById?.data?.data
  const store = invoices?.store;
  const customer = invoiceById?.data?.data?.customer

  const StoreState = invoices?.store?.state
  const customerState = customer?.state
  const sameState = StoreState === customerState

  const { toPDF, targetRef } = usePDF({ filename: `INV-${invoices?.id}.pdf`, page: { margin: Margin.MEDIUM } })




  return isLoading ? (
    // <GlassDiv className="flex items-center justify-center w-full h-full">
    <CircularProgress size={55} />
    // </GlassDiv>
  ) : (
    <div className="relative w-[1080px] mx-auto bg-white overflow -hidden border border-black">
      <div className="flex items-end justify-end p-5">
        <CustomButton className="!bg-purple-400 !rounded-md !text-blue-600" onClick={toPDF} startIcon={<Download />}>
          Download
        </CustomButton>
      </div>
      <div className="flex justify-center w-full px-2 lg:px-10">
        <div ref={targetRef} className="w-[1080px] h-full px-10 py-2 border-collapse" id="container">
          <div className="flex">
            <div className="flex flex-col w-2/3 ">
              <p className="text-lg font-bold text-center pb-9">Tax Invoice</p>
              <div className="flex flex-col gap-1 px-5">
                <span className="flex items-center gap-2">
                  <p className="w-28">IRN</p>
                  <p> : </p>
                  <p className="font-bold">{invoices?.reference_no || "--"}</p>
                </span>
                <span className="flex items-center gap-2">
                  <p className="w-28">Ack No</p>
                  <p> : </p>
                  <p className="font-bold"> {invoices?.acknowledgement_no || "--"} </p>
                </span>
                <span className="flex items-center gap-2">
                  <p className="w-28">Ack Date</p>
                  <p> : </p>
                  <p className="font-bold">{invoices?.date?.slice(0, 10) || "--"}</p>
                </span>
              </div>
            </div>
            <div className="flex flex-col w-1/3">
              <p className="px-5 text-lg font-bold text-center">e-Invoice</p>
              <div className="flex justify-end">
                <img src={store?.store_qr_code_image} alt="" className="p-2 w-52 h-52" />
              </div>
            </div>
          </div>
          <div className="flex border border-black">
            <div className="flex flex-col w-1/2 border-r border-black">
              <div className="flex flex-col p-2 border-b border-black">
                <p className="text-lg font-extrabold capitalize">{store?.store_name || "--"}</p>
                <p>{store?.store_address || "--"}</p>
                <p>{store?.city || "--"}</p>
                <p>GSTIN/UIN : {store?.gst_number || "--"}</p>
                <p>State Name : {store?.state || "--"}</p>
              </div>
              <div className="flex flex-col p-2 border-b border-black">
                <p>Consignee (Ship to)</p>
                <p className="text-lg font-extrabold capitalize">{customer?.company_name || invoices?.customer_name}</p>
                <p>{customer?.area || customer?.city || "--"}</p>
                <p>GSTIN/UIN : {customer?.gst_number || "--"}</p>
                <p>State Name : {customer?.state || "--"}</p>
              </div>
              <div className="flex flex-col p-2">
                <p>Buyer (Bill to)</p>
                <p className="text-lg font-extrabold capitalize">{customer?.company_name || invoices?.customer_name}</p>
                <p>{customer?.area || customer?.city || "--"}</p>
                <p>GSTIN/UIN : {customer?.gst_number || "--"}</p>
                <p>State Name : {customer?.state || "--"}</p>
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="flex border-b border-black">
                <div className="flex flex-col w-1/2 p-2 border-r border-black">
                  <p>Invoice No.</p>
                  <p className="font-bold">{invoices?.invoice_no || "--"}</p>
                </div>
                <div className="flex flex-col w-1/2 p-2">
                  <p>Dated</p>
                  <p className="font-bold">{invoices?.date?.slice(0, 10) || "--"}</p>
                </div>
              </div>
              <div className="flex border-b border-black">
                <div className="flex flex-col w-1/2 p-2 border-r border-black">
                  <p>Delivery Note</p>
                  <p className="font-bold">{invoices?.delivery_partner || "--"}</p>
                </div>
                <div className="flex flex-col w-1/2 p-2">
                  <p>Mode/Terms of Payment</p>
                  <p className="font-bold">{invoices?.payment_mode || "--"}</p>
                </div>
              </div>
              <div className="flex border-b border-black">
                <div className="flex flex-col w-1/2 p-2 border-r border-black">
                  <p>Reference No. & Date</p>
                  <p className="font-bold">
                    {invoices?.reference_no} & {invoices?.date?.slice(0, 10)}
                  </p>
                </div>
                {invoices?.otherwebsite_invoice && (
                  <div className="flex flex-col w-1/2 p-2">
                    <p>Other References</p>
                    <p className="font-bold">{invoices?.otherwebsite_invoice || "--"}</p>
                  </div>
                )}
              </div>
              <div className="flex border-b border-black">
                <div className="flex flex-col w-1/2 p-2 border-r border-black">
                  <p>Buyer's Order No</p>
                  <p className="font-bold">{invoices?.sales_order?.sales_order || "--"}</p>
                </div>
                <div className="flex flex-col w-1/2 p-2">
                  <p>Dated</p>
                  <p className="font-bold">{invoices?.sales_order?.sales_order_date?.slice(0, 10) || "--"}</p>
                </div>
              </div>
              <div className="flex border-b border-black">
                <div className="flex flex-col w-1/2 p-2 border-r border-black">
                  <p>Dispatch Doc No.</p>
                  <p className="font-bold">{invoices?.invoice_no || "--"}</p>
                </div>
                <div className="flex flex-col w-1/2 p-2">
                  <p>Delivery Note Date</p>
                  <p className="font-bold">{invoices?.sales_order?.delivery_expected_date?.slice(0, 10) || "--"}</p>
                </div>
              </div>
              <div className="flex border-b border-black">
                <div className="flex flex-col w-1/2 p-2 border-r border-black">
                  <p>Dispatch through</p>
                  <p className="font-bold">{invoices?.delivery_partner || "--"}</p>
                </div>
                <div className="flex flex-col w-1/2 p-2">
                  <p>Destination</p>
                  <p className="font-bold">
                    {invoices?.customer?.city && invoices?.customer?.city
                      ? invoices?.customer?.city + ", " + invoices?.customer?.state
                      : "--"}
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col w-full p-2">
                  <p>Terms of Delivery</p>
                  <p className="font-bold">{invoices?.payment_terms || "--"}</p>
                </div>
              </div>
            </div>
          </div>
          <table className="w-full border border-t-0 border-collapse border-black">
            <thead className="border border-black border-y-0">
              <tr className="*:p-2 *:border-r border-black border-b *:border-black *:border-collapse">
                <th className="p-2 border-b border-r border-collapse border-black">S.No.</th>
                {store?.product_show_image && <th className="border-r border-black " >Image</th>}
                <th className="p-2 border-b border-r border-collapse border-black" >Description of Items</th>
                <th className="p-2 border-b border-r border-collapse border-black">HSN/SAC</th>
                <th className="p-2 border-b border-r border-collapse border-black" >Quantity</th>
                <th className="p-2 border-b border-r border-collapse border-black" >Rate</th>
                <th className="p-2 border-b border-r border-collapse border-black">per</th>
                <th className="p-2 border-b border-r border-collapse border-black">Dec. %</th>
                <th className="p-2 border-b border-r border-collapse border-black" >Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoices?.invoice_item?.map((item, index) => {
                return (
                  <tr className="*:p-2 *:border-r *:border-black *:border-collapse">
                    <td className="border-r border-black " >{index + 1}</td>
                    {store?.product_show_image && (
                      <td className="border-r border-black " >
                        <img
                          src={`${item.image
                            ? item.image.startsWith("https://aaraerp.s3.amazonaws.com/media/")
                              ? item?.image
                              : "https://aaraerp.s3.amazonaws.com/media/" + item?.image
                            : localStorage.getItem("store_logo")
                            }`}
                          alt="image"
                          className="!h-24  !mx-auto !w-24"
                        />
                      </td>
                    )}
                    <td className="border-r border-black " >
                      {item.item_name || "--"} ({item.slug || "--"})
                    </td>
                    <td className="border-r border-black " align="right">{item.hsn || "--"}</td>
                    <td align="right" className="font-bold">
                      {item.quantity || "--"}
                    </td>
                    <td className="border-r border-black " align="right">{item.rate || "--"} </td>
                    <td className="border-r border-black " align="right">{item.discount || "--"}</td>
                    <td></td>
                    <td align="right" className="font-bold border-r border-black">
                      {item.total_price || "--"}
                    </td>
                  </tr>
                )
              })}

              <tr className="*:py-3 *:border-r *:border-black *:border-collapse">
                <td className="py-3 border-r border-collapse border-black" ></td>
                {store?.product_show_image && <td className="py-3 border-r border-collapse border-black"></td>}
                <td className="py-3 border-r border-collapse border-black"></td>
                <td className="py-3 border-r border-collapse border-black"></td>
                <td className="py-3 border-r border-collapse border-black"></td>
                <td className="py-3 border-r border-collapse border-black"></td>
                <td className="py-3 border-r border-collapse border-black"></td>
                <td className="py-3 border-r border-collapse border-black"></td>
                <td className="py-3 border-r border-collapse border-black"></td>
              </tr>
              <tr className="*:p-2 *:border-r *:border-black *:border-collapse">
                <td className="p-2 border-r border-collapse border-black"></td>
                {store?.product_show_image && <td></td>}
                {sameState ? (
                  <td className="p-2 font-bold border-r border-collapse border-black" align="right" >
                    CGST
                  </td>
                ) : (
                  <td className="p-2 border-r border-collapse border-black"></td>
                )}
                <td className="p-2 border-r border-collapse border-black"></td>
                <td className="p-2 border-r border-collapse border-black"></td>
                <td className="p-2 border-r border-collapse border-black"></td>
                <td className="p-2 border-r border-collapse border-black"></td>
                <td className="p-2 border-r border-collapse border-black"></td>
                {sameState ? (
                  <td className="p-2 font-bold border-r border-collapse border-black" align="right">
                    {invoices?.cgst || "--"}
                  </td>
                ) : (
                  <td className="p-2 border-r border-collapse border-black"></td>
                )}
              </tr>
              <tr className="*:p-2 *:border-r *:border-black *:border-collapse">
                <td className="p-2 border-r border-collapse border-black"></td>
                {store?.product_show_image && <td></td>}
                {sameState ? (
                  <td className="p-2 font-bold border-r border-collapse border-black" align="right" >
                    SGST
                  </td>
                ) : (
                  <td className="p-2 border-r border-collapse border-black" ></td>
                )}
                <td className="p-2 border-r border-collapse border-black" ></td>
                <td className="p-2 border-r border-collapse border-black" ></td>
                <td className="p-2 border-r border-collapse border-black" ></td>
                <td className="p-2 border-r border-collapse border-black" ></td>
                <td className="p-2 border-r border-collapse border-black" ></td>
                {sameState ? (
                  <td align="right" className="p-2 font-bold border-r border-collapse border-black" >
                    {invoices?.sgst || "--"}
                  </td>
                ) : (
                  <td className="p-2 border-r border-collapse border-black" ></td>
                )}
              </tr>
              <tr className="*:py-5 *:border-r *:border-black *:border-collapse">
                <td className="py-5 border-r border-collapse border-black" ></td>
                {store?.product_show_image && <td></td>}
                {!sameState ? (
                  <td align="right" className="py-5 font-bold border border-r border-collapse border-black">
                    IGST
                  </td>
                ) : (
                  <td className="py-5 border-r border-collapse border-black" ></td>
                )}
                <td className="py-5 border-r border-collapse border-black"></td>
                <td className="py-5 border-r border-collapse border-black"></td>
                <td className="py-5 border-r border-collapse border-black"></td>
                <td className="py-5 border-r border-collapse border-black"></td>
                <td className="py-5 border-r border-collapse border-black"></td>
                {!sameState ? (
                  <td align="right" className="py-5 font-bold border border-r border-collapse border-black">
                    {invoices?.igst || "--"}
                  </td>
                ) : (
                  <td className="py-5 border-r border-collapse border-black"></td>
                )}
              </tr>
              {invoices?.delivery_challan?.amount && (
                <tr className="*:py-5 *:border-r *:border-black *:border-collapse">
                  <td className="py-5 border-r border-collapse border-black"></td>
                  {store?.product_show_image && <td></td>}
                  <td align="right" className="py-5 font-bold border-r border-collapse border-black">
                    Delivery Charges
                  </td>
                  <td className="py-5 border-r border-collapse border-black"></td>
                  <td className="py-5 border-r border-collapse border-black"></td>
                  <td className="py-5 border-r border-collapse border-black"></td>
                  <td className="py-5 border-r border-collapse border-black"></td>
                  <td className="py-5 border-r border-collapse border-black"></td>
                  <td align="right" className="py-5 font-bold border-r border-collapse border-black">
                    {invoices?.delivery_challan?.amount}
                  </td>
                </tr>
              )}
              <tr className="*:p-2 *:border-r border-t  *:border-black *:border-collapse">
                <td className="p-2 border-t border-r border-collapse border-black" ></td>
                {store?.product_show_image && <td></td>}
                <td align="right" className="p-2 font-bold border-t border-r border-collapse border-black">
                  Total
                </td>
                <td className="p-2 border-t border-r border-collapse border-black"></td>
                <td align="right" className="p-2 font-bold border-t border-r border-collapse border-black">
                  {invoices?.quantity || invoices?.invoice_item?.reduce((a, b) => a + b?.quantity, 0) || "--"}
                </td>
                <td className="p-2 border-t border-r border-collapse border-black"></td>
                <td className="p-2 border-t border-r border-collapse border-black"></td>
                <td className="p-2 border-t border-r border-collapse border-black"></td>
                <td align="right" className="p-2 font-bold border-t border-r border-collapse border-black">
                  ₹ {invoices?.total_amount || "--"}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="grid grid-cols-2 border border-t-0 border-black">
            <div className="flex flex-col gap-1 p-2 border-r border-black">
              <p>Notes: </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: invoices?.description || "--",
                }}
                className="whitespace-break-spaces"
              />
            </div>
            <div className="flex flex-col gap-1 p-2 ">
              <p>Account Chargeable (in words)</p>
              <p className="text-lg font-bold capitalize">
                {invoices?.total_amount_in_word?.replaceAll(",", "")} Only.
              </p>
            </div>
          </div>

          <table className="w-full border border-t-0 border-collapse border-black">
            <thead>
              <tr className="*:p-2 *:border-r *:border-black *:border-collapse">
                <th className="p-2 border-r border-collapse border-black " rowSpan={2}>HSN/SAC</th>
                <th className="p-2 border-r border-collapse border-black " rowSpan={2}>Taxable Value</th>
                <th className="p-2 border-r border-collapse border-black " colSpan={2}>Central Tax</th>
                <th className="p-2 border-r border-collapse border-black " colSpan={2}>State Tax</th>
                <th className="p-2 border-r border-collapse border-black " colSpan={2}>Integrated Tax</th>
                <th className="p-2 border-r border-collapse border-black " rowSpan={2}>Total Tax Amount</th>
                {invoices?.pay_terms ? <th rowSpan={2}>Late Payment</th> : ""}
                {invoices?.delivery_challan?.amount && <th rowSpan={2}>Delivery Charge</th>}
                <th rowSpan={2}>Total Amount</th>
              </tr>
              <tr className="*:p-2 *:border-r *:border-t *:border-black *:border-collapse">
                <th className="p-2 border-t border-r border-collapse border-black ">Rate</th>
                <th className="p-2 border-t border-r border-collapse border-black ">Amount</th>
                <th className="p-2 border-t border-r border-collapse border-black ">Rate</th>
                <th className="p-2 border-t border-r border-collapse border-black ">Amount</th>
                <th className="p-2 border-t border-r border-collapse border-black ">Rate</th>
                <th className="p-2 border-t border-r border-collapse border-black ">Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoices?.invoice_item?.map((item) => {
                return (
                  <tr className="*:p-2 *:border-r *:border-t *:border-black *:border-collapse">
                    <td className="p-2 border-t border-r border-collapse border-black ">{item.hsn}</td>
                    <td className="p-2 border-t border-r border-collapse border-black " align="right">{item.rate || "--"}</td>
                    <td className="p-2 border-t border-r border-collapse border-black " align="right">{Number(item.tax) / 2}%</td>
                    <td className="p-2 border-t border-r border-collapse border-black " align="right">{item.cgst}</td>
                    <td className="p-2 border-t border-r border-collapse border-black " align="right">{Number(item.tax) / 2}%</td>
                    <td className="p-2 border-t border-r border-collapse border-black " align="right">{item.sgst}</td>
                    <td className="p-2 border-t border-r border-collapse border-black " align="right">{item.tax}%</td>
                    <td className="p-2 border-t border-r border-collapse border-black " align="right">{item.igst}</td>
                    <td className="p-2 border-t border-r border-collapse border-black " align="right">{item.igst}</td>
                    {invoices?.delivery_challan?.amount && <td align="right">- </td>}
                    {invoices?.pay_terms && <td align="right">- </td>}
                    <td align="right">-</td>
                  </tr>
                )
              })}

              <tr className="*:p-2 *:border-r *:border-t *:border-black *:border-collapse">
                <td className="p-2 font-bold border-t border-r border-collapse border-black" align="right">
                  Total
                </td>
                <td className="p-2 font-bold border-t border-r border-collapse border-black" align="right">
                  {invoices?.total_price}
                </td>
                <td className="p-2 font-bold border-t border-r border-collapse border-black"></td>
                <td className="p-2 font-bold border-t border-r border-collapse border-black" align="right">
                  {invoices?.cgst}
                </td>
                <td className="p-2 font-bold border-t border-r border-collapse border-black"></td>
                <td className="p-2 font-bold border-t border-r border-collapse border-black" align="right">
                  {invoices?.sgst}
                </td>
                <td className="p-2 font-bold border-t border-r border-collapse border-black"></td>
                <td className="p-2 font-bold border-t border-r border-collapse border-black" align="right">
                  {invoices?.igst}
                </td>
                <td className="p-2 font-bold border-t border-r border-collapse border-black" align="right">
                  {invoices?.igst}
                </td>

                {invoices?.pay_terms && (
                  <td className="p-2 font-bold border-t border-r border-collapse border-black" align="right">
                    {invoices?.late_fee?.toFixed(2)}
                  </td>
                )}
                {invoices?.delivery_challan?.amount && (
                  <td className="p-2 font-bold border-t border-r border-collapse border-black" align="right">
                    {invoices?.delivery_challan?.amount}
                  </td>
                )}
                <td className="p-2 font-bold border-t border-r border-collapse border-black" align="right">
                  {(Number(invoices?.total_amount) + Number(invoices?.late_fee)).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
          {invoices?.pay_terms == "EMI" && <div className="py-1 text-lg font-bold text-center"> EMI Calculations</div>}
          {invoices?.pay_terms == "EMI" && (
            <table className="w-full border border-collapse border-black">
              <thead>
                <tr className="*:p-2 *:border-r *:border-black *:border-collapse">
                  <th className="p-2 border-r border-collapse border-black">Monthly EMI</th>
                  <th className="p-2 border-r border-collapse border-black">Down Payment</th>
                  <th className="p-2 border-r border-collapse border-black">EMI Duration</th>
                  <th className="p-2 border-r border-collapse border-black">Processing Fee</th>
                  <th className="p-2 border-r border-collapse border-black">Total Interest</th>
                  <th className="p-2 border-r border-collapse border-black">Remaining Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr className="*:p-2 *:border-r *:border-t *:border-black *:border-collapse">
                  <td className="p-2 border-t border-r border-collapse border-black" align="right">{invoices.emi_amount + "  " || "0"}</td>
                  <td className="p-2 border-t border-r border-collapse border-black" align="right">{invoices.down_payment + "  " || "0"}</td>
                  <td className="p-2 border-t border-r border-collapse border-black" align="right">{invoices.tenure_month + "  Months" || "0"}</td>
                  <td className="p-2 border-t border-r border-collapse border-black" align="right">{invoices.processing_fee + "   (" + invoices.processing_rate + "%)" || "0"}</td>
                  <td className="p-2 border-t border-r border-collapse border-black" align="right">{invoices.total_interest + "   (" + invoices.interest_rate + "%)" || "0"}</td>
                  <td className="p-2 border-t border-r border-collapse border-black" align="right">{invoices.loan_amount || "0"}</td>
                </tr>

                {/* <tr className="*:p-2 *:border-r *:border-t *:border-black *:border-collapse">
                <td className="font-bold" align="right">
                  Total
                </td>
                <td className="font-bold" align="right">
                  {invoices?.total_price}
                </td>
                <td className="font-bold"></td>
                <td className="font-bold" align="right">
                  {invoices?.cgst}
                </td>
                <td className="font-bold"></td>
                <td className="font-bold" align="right">
                  {invoices?.sgst}
                </td>
                <td className="font-bold"></td>
                <td className="font-bold" align="right">
                  {invoices?.igst}
                </td>
                <td className="font-bold" align="right">
                  {invoices?.igst}
                </td>
              </tr> */}
              </tbody>
            </table>
          )}
          {invoices.payment_amount?.length !== 0 && (
            <div className="py-1 text-lg font-bold text-center"> Payment Received</div>
          )}
          {invoices.payment_amount?.length !== 0 && (
            <table className="w-full border border-collapse border-black">
              <thead>
                <tr className="*:p-2 *:border-r *:border-black *:border-collapse">
                  <th className="p-2 border-r border-collapse border-black">Payment Date</th>
                  <th className="p-2 border-r border-collapse border-black">Amount ({invoices?.symbol})</th>
                  <th className="p-2 border-r border-collapse border-black">Status</th>
                </tr>
              </thead>
              <tbody>
                {invoices.payment_amount?.map((i) => (
                  <tr className="*:p-2 *:border-r *:border-t *:border-black *:border-collapse">
                    <td className="p-2 border-t border-r border-collapse border-black" align="center">{moment?.(i?.date).format("ll") || "0"}</td>
                    <td className="p-2 border-t border-r border-collapse border-black" align="center">{i?.amount || "0"}</td>
                    <td className="p-2 border-t border-r border-collapse border-black" align="center">
                      <Chip label="Paid" color="success" />
                    </td>
                  </tr>
                ))}

                {/* <tr className="*:p-2 *:border-r *:border-t *:border-black *:border-collapse">
                <td className="font-bold" align="right">
                  Total
                </td>
                <td className="font-bold" align="right">
                  {invoices?.total_price}
                </td>
                <td className="font-bold"></td>
                <td className="font-bold" align="right">
                  {invoices?.cgst}
                </td>
                <td className="font-bold"></td>
                <td className="font-bold" align="right">
                  {invoices?.sgst}
                </td>
                <td className="font-bold"></td>
                <td className="font-bold" align="right">
                  {invoices?.igst}
                </td>
                <td className="font-bold" align="right">
                  {invoices?.igst}
                </td>
              </tr> */}
              </tbody>
            </table>
          )}

          <div className="flex items-center gap-1 p-2 border border-black border-y-0">
            <p>Tax Amount (in words)</p> <p>:</p>{" "}
            <p className="text-lg font-bold capitalize">{invoices?.igst_in_word?.replaceAll(",", "")} Only.</p>
          </div>
          <div className="flex border border-t-0 border-black">
            <div className="flex flex-col w-1/2 p-2">
              <p className="underline">Declaration</p>
              <p className="leading-5">
                We Declare that this invoice shows the actual price of the goods described nd that all particulars are
                true and correct.
              </p>
            </div>
            <div className="flex flex-col justify-between w-1/2 border-t border-l border-black">
              <p className="p-1 text-lg font-extrabold text-end">for {store?.store_name}</p>
              <div className="flex justify-end">
                <img src={store?.signature} alt="" className="w-32 h-20 p-2" />
              </div>
              <p className="p-2 text-end">Authorized Signatory</p>
            </div>
          </div>
          <p className="py-1 text-center">This is a Computer Generated Invoice</p>
        </div>
      </div>
    </div>
  )
}

import { countryStateCityFn } from "../Services/POS/Services"
import { useQuery } from "react-query"
import { backgroundFn } from "../Services/Backgrounds"
/* import { storePermissionFn } from "Services/Permission" */
import { storeProfileFn } from "../Services/Profile"
import { storeSettingsFn } from "../Services/Settings/GeneralSettings"
import { storeDetails } from "../Services/Store/StoreDetails"
import useLocalStorage from "../Shared/useLocalStorage"

/**
 * Custom hook to check permission for a specific module and permission name.
 * @param {("INVENTORY" | "CRM" | "FINANCE" | "SUPPORT" | "POS" | "REPORT" | "BILLING" | "HRM" | "DOCUMENT" | "POS-SUPPORT" | "POS-INVENTORY")} moduleName - Name of the module.
 * @param {string} permissionName - Name of the permission.
 * @returns {boolean} - Indicates whether the user has permission.
 */
/* export const usePermission = (moduleName, permissionName) => {
  const role = localStorage.getItem("role")
  const isStoreAdmin = role === "Store Admin"
  if (isStoreAdmin) return true
  const employeeId = localStorage.getItem("employee_id")
  const { data } = useQuery("usePermission", () => storePermissionFn({ emp_id: employeeId }), {
    enabled: !isStoreAdmin,
  })
  const permissions = data?.data?.data || []
  const module = permissions.find((i) => i.module_name === moduleName)
  const permission = module?.permission.find((i) => i.codename === permissionName)?.active_status || false
  return permission
} */

/**
 * Custom hook to fetch settings data using react-query.
 *
 * @returns {{
 *   store_qr_code_image: string,
 *   store_qr_code_unique_no: string,
 *   store_name: string,
 *   store_address: string,
 *   store_mobile: number,
 *   country: string,
 *   state: string,
 *   city: string,
 *   pincode: string,
 *   IFSC: string,
 *   bank_name: string,
 *   account_no: string,
 *   current_balance: string,
 *   email: string,
 *   gst_number: string,
 *   pan_number: string,
 *   business_legal_name: (string|null),
 *   business_trade_name: (string|null),
 *   gst_registered_on: (string|null),
 *   website: string,
 *   swift_code: string,
 *   logo: string,
 *   upi_code: string,
 *   signature: string,
 *   latitude: string,
 *   longitude: string,
 *   currency: string,
 *   flag: string,
 *   is_store_payment_accepted_by: string,
 *   variant_status: boolean,
 *   spec_status: boolean,
 *   sync_status: boolean,
 *   is_real_time_sync: boolean,
 *   bulk_product_status: boolean,
 *   barcode_export_status: boolean,
 *   asset_management_status: boolean,
 *   rack_setting_status: boolean,
 *   measuring_unit_setting_status: boolean,
 *   manage_product_variant: boolean,
 *   multiple_warehouse_status: boolean,
 *   warehouse_limit_creation: number,
 *   delivery_manages_by: string,
 *   vendor_mail_status: boolean,
 *   vendor_whatsapp_status: boolean,
 *   vendor_sms_status: boolean,
 *   customer_mail_status: boolean,
 *   customer_whatsapp_status: boolean,
 *   customer_sms_status: boolean,
 *   candiate_mail_status: boolean,
 *   candiate_whatsapp_status: boolean,
 *   candiate_sms_status: boolean,
 *   employee_mail_status: boolean,
 *   employee_whatsapp_status: boolean,
 *   employee_sms_status: boolean,
 *   estimate_mail_status: boolean,
 *   estimate_whatsapp_status: boolean,
 *   estimate_sms_status: boolean,
 *   purchase_mail_status: boolean,
 *   purchase_whatsapp_status: boolean,
 *   purchase_sms_status: boolean,
 *   sales_mail_status: boolean,
 *   sales_whatsapp_status: boolean,
 *   sales_sms_status: boolean,
 *   negative_billing: boolean,
 *   custom_sales_billing: boolean,
 *   hiring_status: boolean,
 *   candidate_bulk_upload_status: boolean,
 *   hiring_history_status: boolean,
 *   attendance_status: boolean,
 *   attendance_bulk_upload_status: boolean,
 *   team_status: boolean,
 *   whatsapp_chat_in_hrm_status: boolean,
 *   leads_status: boolean,
 *   sales_target_status: boolean,
 *   customer_bulk_upload_status: boolean,
 *   whatsapp_chat_in_crm_status: boolean,
 *   gst_filling_status: boolean,
 *   email_calendar_invitaion: boolean,
 *   manufacturing_status: boolean,
 *   is_store_active_status: boolean,
 *   alternate_barcode_status: boolean,
 *   is_store_active_for_Bhaarat_Store: boolean,
 *   is_store_active_for_Bhaarat_ERP_Bussiness_WhatsAapp: boolean,
 *   status: number,
 *   active_status: number,
 *   is_reseller: boolean,
 *   reseller_id: string,
 *   business_type: number,
 *   license_status: boolean
 * }} The settings data.
 */
export const useSettings = () => {
  const { data } = useQuery("useSettings", storeSettingsFn)
  return data?.data?.data
}

/**
 * Custom hook for user profile data.
 * @returns {{profile: Object, modules: Object, license: Object, background:string, isLoading: boolean}}
 */
export const useProfile = () => {
  const { data, isLoading } = useQuery("useProfile", storeProfileFn)
  const { profile, plan, license, current_backgroung_image } = data?.data || {}
  const modules = plan?.module
  const background = current_backgroung_image?.backgroung_image
  return { profile, modules, license, background, isLoading }
}

/**
 * Custom hook for fetching country, state, and city data.
 * @param {Object} options - Options object containing country_id and state_id.
 * @param {string} options.country_id - The ID of the selected country.
 * @param {string} options.state_id - The ID of the selected state.
 * @returns {{
 *   countries: Array,
 *   isLoadingCountries: boolean,
 *   states: Array,
 *   isLoadingStates: boolean,
 *   cities: Array,
 *   isLoadingCities: boolean
 * }} Object containing countries, states, and cities data along with loading states.
 */
export const useCountry = ({ country_id = "", state_id = "", enabled = true }) => {
  const { data: countriesData, isLoading: isLoadingCountries } = useQuery(["countries"], () => countryStateCityFn(), {
    enabled: enabled,
  })
  const countries = countriesData?.data?.data?.map(({ name, id }) => ({ label: name, value: id }))
  const { data: statesData, isLoading: isLoadingStates } = useQuery(
    ["states", country_id],
    () => countryStateCityFn({ country_id }),
    { enabled: Boolean(country_id && enabled) }
  )

  const states = statesData?.data?.data?.map(({ state, id }) => ({ label: state, value: id }))
  const { data, isLoading: isLoadingCities } = useQuery(["cities", country_id, state_id], () =>
    countryStateCityFn({ country_id, state_id }, { enabled: Boolean(country_id && state_id && enabled) })
  )
  const cities = data?.data?.data?.map(({ city, id }) => ({ label: city, value: id }))
  return { countries, isLoadingCountries, states, isLoadingStates, cities, isLoadingCities }
}

/**
 * Custom hook for fetching store details.
 * @returns {{
 *   store: Object,
 *   isLoading: boolean
 * }} Object containing store details and loading status.
 */
export const useStore = () => {
  const { data, isLoading } = useQuery("store", () => storeDetails())
  const store = data && data?.data?.data
  return { store, isLoading }
}

export const useBackground = () => {
  const [background, setBackground] = useLocalStorage(
    "background",
    "https://pub-8bfefaef623241d6b30869ce2ceeb9ac.r2.dev/assets/Sales.png"
  )
  const isBackground = localStorage.getItem("background")
  useQuery(["useBackground"], () => backgroundFn(), {
    enabled: !isBackground,
    onSuccess: ({ data }) => {
      data.current_backgroung_image && setBackground(data.current_backgroung_image)
    },
  })
  return { background }
}


import axios from "axios";

export const baseURL = "https://erpstaging.aaragroups.com"
// process.env.NODE_ENV === "development"
//   ? "https://erpstaging.aaragroups.com"
//   : "https://erp.aaragroups.com";
/*  "https://b1.bhaaraterp.com" */

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use(
  /**
   * Interceptor function to add Authorization header to the request config.
   * @param {import("axios").AxiosRequestConfig} config - Axios request config.
   * @returns {import("axios").AxiosRequestConfig} - Updated Axios request config.
   */
  (config) => {
    const Token = localStorage.getItem("customersToken");
    const Authorization = localStorage.getItem("customersToken");
    config.headers = { Authorization, Token, ...config.headers };
    return config;
  },

  /**
   * Interceptor error handler for request.
   * @param {import("axios").AxiosError} error - Axios error object.
   * @returns {Promise<never>} - Promise rejection with error.
   */
  (error) => Promise.reject(error)
);

export default axiosInstance;

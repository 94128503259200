import { AccountCircle, LockReset, Logout, Store, TaskAlt, TextSnippet } from "@mui/icons-material"
import { Avatar, Divider, ListItemButton, Menu } from "@mui/material"
import { logoutUserFn } from "../../Services/Login"
import { checkInCheckOutInDetailFn, checkInCheckOutInFn } from "../../Services/Store/StoreDetails"
import { useProfile } from "../../Settings"
import classNames from "classnames"
import { useState } from "react"
import { useCurrentLocation } from "react-mkx-toolkit"
import { useMutation, useQuery } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import TwoFactorAnable from "./TwoFactorAnable"
import { closeCounter } from "../../Services/POS/Services"

export const LogoutBusiness = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("store")
  localStorage.removeItem("user_id")
  localStorage.removeItem("email")
  localStorage.removeItem("user_name")
  localStorage.removeItem("store_name")
  localStorage.removeItem("store_logo")
  localStorage.removeItem("ExpDate")
  localStorage.removeItem("employee_id")
  localStorage.removeItem("currency")
  localStorage.removeItem("role")
  localStorage.removeItem("two_step")
  localStorage.removeItem("manage_product_variant")
  localStorage.removeItem("ip_address")
  localStorage.removeItem("module")
  localStorage.removeItem("tax_type")
  sessionStorage.clear()
  window.location.href = "/"
}

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { latitude, longitude } = useCurrentLocation()
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const two_step = localStorage.getItem("two_step")
  const isAdmin = localStorage.getItem("role") === "Store Admin"

  const { mutate: logout } = useMutation(logoutUserFn, {
    onSuccess: ({ data }) => {
      if (data.response_code === 200) {
        LogoutBusiness()
      } else {
        toast.error(data.message)
      }
    },
  })
  const { mutate: closeCounterMutation } = useMutation(closeCounter, {
    onSuccess: (res) => {
      if (res.data.response_code === 200) {
        toast.success(res.data.message)
      }
    },
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { profile } = useProfile()

  const name = profile ? `${profile?.first_name} ${profile?.last_name}` : localStorage.getItem("user_name")
  const email = localStorage.getItem("email")
  const logo = profile ? profile?.profile_pic : localStorage.getItem("store_logo")

  const { data, refetch } = useQuery(["checkInCheckOutInDetail"], () => checkInCheckOutInDetailFn())

  const { mutate: checkInCheckOutIn } = useMutation(checkInCheckOutInFn, {
    onSuccess: ({ data }) => {
      toast.success(data.message)
      setAnchorEl(null)
      refetch()
    },
  })

  const status = data?.data?.data

  return (
    <>
      <Avatar src={logo} alt={name} onClick={handleClick} className="!text-2xl !bg-opacity-20 !text-white" />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="!top-2"
        MenuListProps={{ className: "!w-56" }}
      >
        <div className="flex flex-col items-center justify-center gap-2 py-3">
          <Avatar alt={name} src={logo} className="!text-2xl !capitalize !h-14 !w-14" />
          <div className="flex flex-col items-center justify-center ">
            <p className="capitalize">{name}</p>
            <p className="text-xs">{email}</p>
          </div>
        </div>
        <Divider />
        <Link to="/profile">
          <ListItemButton className="!flex gap-2" onClick={handleClose}>
            <AccountCircle /> Profile
          </ListItemButton>
        </Link>

        {isAdmin && (
          <Link to="/store">
            <ListItemButton className="!flex gap-2" onClick={handleClose}>
              <Store /> My Store
            </ListItemButton>
          </Link>
        )}
        {!isAdmin && (
          <ListItemButton
            disabled={status === "checked out"}
            onClick={() =>
              latitude && longitude
                ? checkInCheckOutIn({ latitude, longitude })
                : toast.error("Location permission required.")
            }
          >
            <span
              className={classNames(
                "flex gap-2 !capitalize items-center",
                status === "check in" ? "!text-green-500" : status === "check out" ? "!text-red-500" : "!text-blue-500"
              )}
            >
              <TaskAlt /> {status}
            </span>
          </ListItemButton>
        )}
        {!isAdmin && (
          <ListItemButton
            className="!flex gap-2"
            onClick={() => {
              navigate("/leave-counter")
              handleClose()
            }}
          >
            <TextSnippet /> Leave Counter
          </ListItemButton>
        )}
        <TwoFactorAnable twoStep={two_step} handleClose1={handleClose} />
        <Link to="/reset-password">
          <ListItemButton className="!flex gap-2" onClick={handleClose}>
            <LockReset />
            Change Password
          </ListItemButton>
        </Link>
        <ListItemButton
          className="!flex gap-2"
          onClick={() => {
            logout()
            closeCounterMutation({
              cash_counter_id: localStorage.getItem("cash_counter_id"),
            })
          }}
        >
          <Logout />
          Logout
        </ListItemButton>
      </Menu>
    </>
  )
}

export default ProfileMenu

import axios from "axios"
import { toast } from "react-toastify"
import { LogoutBusiness } from "../Shared/ProfileMenu"

/**
 * Base URL for API requests.
 * @type {string}
 */

// export const baseURL = process.env.REACT_APP_API_URL_LIVE
// export const baseURL = "http://192.168.1.188:9094/"
// export const baseURL = "https://erp.aaragroups.com/"
//export const baseURL = process.env.REACT_APP_API_URL_LOCAL
export const baseURL = "https://erpstaging.aaragroups.com"
// 
/**
 * Axios instance with baseURL set.
 * @type {import("axios").AxiosInstance}
 */

const axiosInstance = axios.create({ baseURL })

axiosInstance.interceptors.request.use(
    /**
     * Interceptor function to add Authorization header to the request config.
     * @param {import("axios").AxiosRequestConfig} config - Axios request config.
     * @returns {import("axios").AxiosRequestConfig} - Updated Axios request config.
     */

    (config) => {
        const Token = localStorage.getItem("token")
        const Authorization = localStorage.getItem("token")
        config.headers = { Authorization, Token, ...config.headers }
        return config
    },

    /**
     * Interceptor error handler for request.
     * @param {import("axios").AxiosError} error - Axios error object.
     * @returns {Promise<never>} - Promise rejection with error.
     */
    (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
    /**
     * Interceptor function for successful response.
     * @param {import("axios").AxiosResponse} response - Axios response object.
     * @returns {import("axios").AxiosResponse} - The response object.
     */
    (response) => response,
    /**
     * Interceptor error handler for response.
     * @param {import("axios").AxiosError} error - Axios error object.
     * @returns {Promise<never>} - Promise rejection with error.
     */
    (error) => {
        if (error.response && error.response.status === 401) {
            toast.warning(error.response.data.message)
            const timeOut = setTimeout(LogoutBusiness, 3000)
            return () => clearTimeout(timeOut)
        }
        return Promise.reject(error)
    }
)

export default axiosInstance

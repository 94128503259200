import { Download, Mail, PictureAsPdf, Print } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDiv from "../../Shared/CustomDiv";
import Text from "../../Shared/Text";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import { Helmet } from "react-helmet";
import { baseURL } from "../../Config/BaseUrl";
import CustomerOverview from "./CustomerOverview/CustomerOverview";
import { getFn } from "../../Services";
import { useQuery } from "react-query";
import LoyalityAccount from "./LoyalityAccount";
import { CustomerLedger } from "../../Services/CustomerLedger";
import moment from "moment";
import { Margin, usePDF } from "react-to-pdf";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Customers = () => {
  const [value, setValue] = React.useState("1");
  const [data1, setData1] = useState([]);
  const [store, setStore] = useState([]);
  const [storeId, setStoreId] = useState(null);
  const [refetchDetails, setRefetchDetails] = useState(1);
  const [ledger, setLedger] = useState([])
  const [dateFilter, setDateFilter] = useState()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange1 = (event) => {
    setStoreId(event.target.value);
  };

  useEffect(() => {
    const getStore = () => {
      axios
        .get(
          `${baseURL}/customer-pannel/customer-store-list-api-in-bhaaraterp/`,
          {
            headers: {
              authorization: localStorage.getItem("customersToken"),
            },
          }
        )
        .then((response) => {
          setStore(response?.data?.store_list);
          setStoreId(response?.data?.store_list?.[0]?.store_id);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getStore();
  }, []);

  useEffect(() => {
    const getData = () => {
      axios
        .get(
          `${baseURL}/customer-pannel/customer-details-api-in-bhaaraterp/?store_id=${storeId}`,
          {
            headers: {
              authorization: localStorage.getItem("customersToken"),
            },
          }
        )
        .then((response) => {

          setData1(response?.data?.data);
          console.log(">>>>>>>>>>>>>>>>", response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    };

    storeId && getData(storeId);
  }, [storeId, refetchDetails]);

  const { data: loyaltyData } = useQuery(
    ["loyaltyData", storeId],
    () =>
      getFn(
        { store_id: storeId },
        "/customer-pannel/customer-loyalty-account-api/"
      ),

    {
      retry: 1,
      enabled: storeId !== null,
      select: (data) => data?.data?.data,
    },
    console.log(data1)
  );
  const fetchData = async (s_id, c_id, dateFilters) => {

    console.log("Filter Datae", dateFilters)
    const data = await CustomerLedger(s_id, c_id, dateFilters);


    console.log(data?.data?.data)

    setLedger(data?.data?.data)

  }




  useEffect(() => {

    fetchData(storeId, data1?.id, dateFilter);



  }, [data1, storeId, dateFilter])






  const { toPDF, targetRef } = usePDF({
    filename: `INV-1.pdf`,
    page: { margin: Margin.MEDIUM },
  });
  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Customers Dashboard BhaaratErp Panel</title>
          <link rel="canonical" href="https://bharaterp.org/" />
        </Helmet>
      </div>
      <CustomDiv>
        <div className="flex flex-col my-5">
          <span className="justify-between px-5 py-3 border shadow-md lg:flex">
            <Text className="text-lg lg:text-2xl">
              {localStorage.getItem("customer_name")}
            </Text>
            <div className="flex items-center p-2 lg:gap-10 lg:my-0">
              <LoyalityAccount
                customer_id={loyaltyData?.customer}
                isActivated={Boolean(loyaltyData)}
                // total_loyality_point={loyaltyData?.total_active_loyalty_point}
                store_id={storeId}
              />
              <FormControl sx={{ m: 1, minWidth: 90 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Store
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={storeId || ""}
                  onChange={handleChange1}
                  autoWidth
                  label="Age"
                  className="!h-10 !lg:text-lg ! !text-sm"
                >
                  {store?.map((item) => {
                    return (
                      <MenuItem
                        key={item.store_id}
                        value={item.store_id}
                        className="text-sm"
                      >
                        {item.store_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </span>
        </div>
        <Divider />
        <Box
          sx={{
            flexGrow: 1,
            display: "lg:!flex",
          }}
        >
          <TabContext value={value} className="!flex ">
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              orientation="horizontal"
              variant="scrollable"
            >
              <Tab label="Overview" value="1" />
              <Tab label="Statement" value="2" />
            </TabList>
            <span className="flex flex-col w-full">
              <TabPanel value="1" className="!w-full !p-2 lg:flex">
                <CustomerOverview
                  storeId={storeId}
                  data1={data1}
                  setRefetchDetails={setRefetchDetails}
                />
              </TabPanel>
              <TabPanel value="2" className="flex flex-col  !p-0 !px-5 !w-full">
                <span className="flex flex-col items-center justify-between w-full gap-3 md:flex-row">
                  <span className="flex flex-col gap-2 !text-sm  md:flex-row">
                    <Select onChange={(e) => setDateFilter(e.target.value)} className="text-sm text-black !w-48" defaultValue="All" size="small">
                      <MenuItem value="Today"> Today</MenuItem>
                      <MenuItem value="This Week"> This Week</MenuItem>
                      <MenuItem value="This Month"> This Month</MenuItem>
                      <MenuItem value="This Quarter"> This Quarter</MenuItem>
                      <MenuItem value="This Year"> This Year</MenuItem>
                      <MenuItem value="This Month"> This Month</MenuItem>
                      <MenuItem value="All"> All</MenuItem>
                    </Select>
                    {/* <Select className="text-sm" defaultValue={1} size="small">
                      <MenuItem value="1"> Filter By : All</MenuItem>
                      <MenuItem value="1"> Filter By : Outstanding</MenuItem>
                    </Select> */}
                  </span>
                  <span className="flex gap-2">
                    <IconButton onClick={toPDF}   >
                      <Print />
                    </IconButton>
                    <IconButton>
                      <PictureAsPdf />
                    </IconButton>
                    <IconButton>
                      <Download />
                    </IconButton>
                    {/* <Button
                      disableElevation
                      variant="contained"
                      size="small"
                      startIcon={<Mail />}
                    >
                      Send Mail
                    </Button> */}
                  </span>
                </span>
                {/* <span className="flex flex-col items-center justify-center w-full mt-10">
                  <Text className="!text-sm font-semibold md:text-lg">
                    Customer Statement for Mr. Mani Kant Sharma
                  </Text>
                  <Text>From 01/01/2023 To 31/03/2023</Text>
                </span>
                <div className="flex flex-col gap-2 !pb-10">
                  <span className="flex flex-col text-sm md:text-lg text-end">
                    <Text>Aara Texchnologies</Text>
                    <Text>Uttar Pradesh</Text>
                    <Text>India</Text>
                  </span>
                  <span className="flex text-sm lg:!justify-center gap-3 md:text-lg">
                    <span>
                      <Text>To</Text>
                      <Text>Mr. Mani Kant Sharma</Text>
                    </span>
                    <span>
                      <Text className="!text-xl font-semibold border-b-2 border-black md:!text-3xl">
                        Statement of Accounts
                      </Text>
                      <Text className="text-sm border-b-2 border-black text-end md:text-lg">
                        01/01/2023 To 31/03/2023
                      </Text>
                      <Text className="mt-4 bg-slate-200">Account Summary</Text>
                      <span className="flex items-center justify-between">
                        <Text>Opening Balance</Text>
                        <Text>₹ 0.00</Text>
                      </span>
                      <span className="flex items-center justify-between">
                        <Text>Invoiced Amount</Text>
                        <Text>₹ 0.00</Text>
                      </span>
                      <span className="flex items-center justify-between">
                        <Text>Amount Received </Text>
                        <Text>₹ 0.00</Text>
                      </span>
                      <span className="flex items-center justify-between pt-2 border-t-2 border-black">
                        <Text>Opening Balance</Text>
                        <Text>₹ 0.00</Text>
                      </span>
                    </span>
                  </span>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            background: "black",
                            color: "white",
                            padding: 2,
                          }}
                        >
                          <TableCell className="!text-white !p-2">
                            Date
                          </TableCell>
                          <TableCell align="right" className="!text-white !p-2">
                            Transactions
                          </TableCell>
                          <TableCell align="right" className="!text-white !p-2">
                            Details
                          </TableCell>
                          <TableCell align="right" className="!text-white !p-2">
                            Amount
                          </TableCell>
                          <TableCell align="right" className="!text-white !p-2">
                            Payments
                          </TableCell>
                          <TableCell align="right" className="!text-white !p-2">
                            Balance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {[].map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <span className="flex justify-end">
                    <span className="flex w-[60%]">
                      <Text>Balance Due</Text> <Text> ₹ 0.00</Text>
                    </span>
                  </span>
                </div> */}



                <div className="!hidden lg:!block">
                  <Table ref={targetRef} className="mt-5 mb-5 bg-white bg-opacity-20 ">
                    <thead>

                      <TableRow className="bg-gray-600 bg-opacity-40">
                        <TableCell className="border-r-2 !py-1.5 !font-bold !text-center !border-white !border-opacity-50">
                          Date
                        </TableCell>
                        <TableCell className="border-r-2 !py-1.5 !font-bold !text-center !border-white !border-opacity-50">
                          Invoice Ammount
                        </TableCell>
                        <TableCell className="border-r-2 !py-1.5 !font-bold !text-center !border-white !border-opacity-50">
                          Recipt No.
                        </TableCell>
                        <TableCell className="border-r-2 !py-1.5 !font-bold !text-center !border-white !border-opacity-50">
                          Recipt Type
                        </TableCell>
                        <TableCell className="border-r-2 !py-1.5 !font-bold !text-center !border-white !border-opacity-50">
                          Payment Type
                        </TableCell>
                        <TableCell className="border-r-2 !py-1.5 !font-bold !text-center !border-white !border-opacity-50">
                          Account Name
                        </TableCell>
                        <TableCell className="border-r-2 !py-1.5 !font-bold !text-center !border-white !border-opacity-50">
                          Balance
                        </TableCell>

                      </TableRow>
                    </thead>
                    <tbody>
                      {ledger?.map((row, index) => (
                        <TableRow className={`${index % 2 == 0 ? "bg-gray-200 !bg-opacity-35" : " "} py-1.5`} key={row.name}>
                          <TableCell className="border-r-2 !py-1.5 border-l-2 !text-center !border-gray-300  !border-opacity-50"> {moment(row?.date).format("DD-MM-YYYY")}</TableCell>
                          <TableCell className="border-r-2 !py-1.5 !text-center !border-gray-300  !border-opacity-50">
                            {row?.invoice_amount}
                          </TableCell>
                          <TableCell className="border-r-2 !py-1.5 !text-center !border-gray-300  !border-opacity-50">{row?.receipt_no}</TableCell>
                          <TableCell className="border-r-2 !py-1.5 !text-center !border-gray-300  !border-opacity-50">{row?.receipt_type}</TableCell>
                          <TableCell className="border-r-2 !py-1.5 !text-center !border-gray-300  !border-opacity-50">{row?.payment_type || "--"}                          </TableCell>
                          <TableCell className="border-r-2 !py-1.5 !text-center !border-gray-300  !border-opacity-50">{row?.annotated_account_name || "--"}</TableCell>
                          <TableCell className="border-r-2 !py-1.5 !text-center !border-gray-300  !border-opacity-50">{row?.balance}</TableCell>


                        </TableRow>
                      ))}
                    </tbody>

                  </Table>
                </div>


                <div className="flex flex-col gap-y-3">
                  {ledger?.map((row, index) => (
                    <div className={`${index % 2 == 0 ? "bg-gray-200 !bg-opacity-35" : " "} p-3 flex flex-col rounded-md shadow-lg  text-sm`} >
                      <div className="flex ">
                        <span className="font-semibold ">Date</span>
                        <span>:</span>
                        <span>{moment(row?.date).format("DD-MM-YYYY")}</span>
                      </div>
                      <div className="flex ">
                        <span className="font-semibold ">Invoice Ammount</span>
                        <span>:</span>
                        <span>{row?.invoice_amount}</span>
                      </div>
                      <div className="flex ">
                        <span className="font-semibold ">Recipt No.</span>
                        <span>:</span>
                        <span>{row?.receipt_no}</span>
                      </div>
                      <div className="flex ">
                        <span className="font-semibold ">Recipt Type</span>
                        <span>:</span>
                        <span>{row?.receipt_type} </span>
                      </div>
                      <div className="flex ">
                        <span className="font-semibold ">Payment Type</span>
                        <span>:</span>
                        <span>{row?.payment_type || "--"}</span>
                      </div>
                      <div className="flex ">
                        <span className="font-semibold ">Account Name</span>
                        <span>:</span>
                        <span>{row?.annotated_account_name || "--"}</span>
                      </div>

                      <div className="flex ">
                        <span className="font-semibold ">Balance</span>
                        <span>:</span>
                        <span>{row?.balance}</span>
                      </div>




                    </div>
                  ))}
                </div>



              </TabPanel>


              {ledger?.length === 0 &&


                (<div className="flex flex-col items-center justify-center ">

                  <img src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-25063.jpg?t=st=1732958381~exp=1732961981~hmac=677cfbe87c851494044721057914428ed47b3c36c64023be3bac1c889794243e&w=826"


                    className="w-[20vw]  "
                  />
                  <p className="text-xl font-bold ">No Data Found</p>
                </div>)}
            </span>
          </TabContext>
        </Box>
      </CustomDiv>
    </>
  );
};

export default Customers;

import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { Chip, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import noRecordEstimates from "../../Assets/noRecordEstimates.svg";
import Text from "../../Shared/Text";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useCallback, useEffect, useState } from "react";
import { baseURL } from "../../Config/BaseUrl";
import moment from "moment";

function createData(
  name,
  calories,
  fat,
  carbs,
  protein,
  newRow
  // dueDate,
  // balanceDue
  // // pdf
) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    newRow,
    // dueDate,
    // balanceDue,
    // pdf
  };
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const TicketHistory = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [data, setData] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [customerData, setCustomerData] = useState([]);
  const [store, setStore] = useState([]);
  const [storeId, setStoreId] = useState(null);

  const getStore = () => {
    axios
      .get(
        `${baseURL}/customer-pannel/customer-store-list-api-in-bhaaraterp/`,
        {
          headers: {
            authorization: localStorage.getItem("customersToken"),
          },
        }
      )
      .then((response) => {
        setStore(response?.data?.store_list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getStore();
  }, []);
  useEffect(() => {
    setStoreId(store?.[0]?.store_id);
  }, [store]);

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            All Service
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Tooltip title="Filter">
              <IconButton>
                <FilterList />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Toolbar>
    );
  }
  const rows = customerData?.map((i) =>
    createData(
      i?.age_of_product,
      i?.book_service_type,
      i?.quantity,
      i?.customer?.first_name,
      i?.customer?.mobile_no,
      i?.customer?.email
    )
  );

  const getData = useCallback(() => {
    axios
      .get(
        `${baseURL}/customer-pannel/customer-ticket-list-api-of-bhaaraterp/?store_id=${storeId}`,
        {
          headers: {
            authorization: localStorage.getItem("customersToken"),
          },
        }
      )
      .then((response) => {
        setCustomerData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [storeId]);

  useEffect(() => {
    storeId && getData();
  }, [getData, storeId]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return data ? (
    <div className="center flex-col gap-2 h-[60vh]">
      <img src={noRecordEstimates} alt="" />
      <Text className="text-lg">No Invoices Record Found</Text>
    </div>
  ) : (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected?.length} />
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Store</InputLabel>
          <Select
            value={storeId || ""}
            onChange={(event) => setStoreId(event.target.value)}
            autoWidth
            label="Age"
            className="!h-10"
          >
            {store?.map((item) => {
              return (
                <MenuItem key={item.store_id} value={item.store_id}>
                  {item.store_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TableContainer
          component="div"
          className="!border !border-gray-600 !border-opacity-30 !rounded"
        >
          <Table className="!overflow-x-auto bg-white !bg-opacity-20">
            <TableHead>
              <TableRow className="!bg-gray-700 !bg-opacity-40 cursor-pointer">
                <TableCell className=" !whitespace-nowrap !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Sr. No.
                </TableCell>
                <TableCell className=" !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Date
                </TableCell>
                <TableCell className=" !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Ticket No
                </TableCell>
                <TableCell className=" !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Manager
                </TableCell>
                <TableCell className=" !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Engineer
                </TableCell>
                <TableCell className=" !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Service
                </TableCell>
                <TableCell className=" !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Charge ({localStorage.getItem("currency_symbol")})
                </TableCell>
                <TableCell className=" !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Issue Type
                </TableCell>
                <TableCell className=" !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Issue
                </TableCell>
                <TableCell className=" !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Remark
                </TableCell>
                <TableCell className=" !py-3 !font-bold !text-center !border-gray-600 !border-opacity-30">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerData?.map((item, index) => {
                return (
                  <TableRow
                    className={`${item.priority_type === "Normal"
                      ? "bg-orange-600 "
                      : "bg-red-600"
                      } hover:!bg-white hover:!bg-opacity-50 !bg-opacity-40 cursor-pointer`}
                    key={item.id}
                  >
                    <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30`}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      {item?.ticket_no}
                    </TableCell>

                    <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      {moment(item?.date).format("ll")}
                    </TableCell>
                    <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      {item.service_manager}
                    </TableCell>
                    <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      {item.service_engineer?.[0]}
                    </TableCell>
                    {/* <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      {item.issue_type}
                    </TableCell> */}
                    <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      {item.service}
                    </TableCell>
                    <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      {item.payable_amount}
                    </TableCell>
                    {/* <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      {item.priority_type}
                    </TableCell> */}
                    <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      {item.issue}
                    </TableCell>
                    <TableCell
                      className={`  !py-2 !w-[5%] !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      {item.remarks}
                    </TableCell>
                    <TableCell
                      className={`  !py-2 !text-center !border-gray-600 !border-opacity-30 `}
                    >
                      <Chip
                        color={`${item?.ticket_status === "Accepted"
                          ? "success"
                          : item?.ticket_status === "Pending"
                            ? "secondary"
                            : item?.ticket_status === "Rejected"
                              ? "error"
                              : "warning"
                          }`}
                        label={`${item?.ticket_status}`}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default TicketHistory;

import { API_URLS } from "../../../Config/apiUrls"
import axiosInstance from "../../../Config/axio.config"

export const itemsListFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.itemsList, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const customersFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.customers, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const orderListFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.orderList, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const deleteOrderFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.deleteOrder, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const lastOrderListFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.lastOrder, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const searchOrderListFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.searchOrder, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const createOrderFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.orderList, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const updateOrderFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.editOrder, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const holdCartFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.holdCart, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const createCustomerFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.createCustomer, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const getHoldCartFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.holdCart, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const countryStateCityFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.countryStateCity, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const additionalChargeFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.additionalCharge, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const paymentMethodsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.paymentMethods, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const paymentMethodsByTypeFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.paymentMethodsByType, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const paymentListFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.paymentList, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const creditNotesListFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.creditNotesList, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const refundOrderFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.returnRefund, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const posSettingsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.posSetting, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const getCouponsFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.coupons, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const applyCouponFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.coupons, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const changePosSettingsFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.posSetting, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const batchCheckFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.batchCheck, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const serviceHistoryFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.serviceHistory, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const questionnaireForService = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.questionnaireFromBookingId, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const posCustomersFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.customersList, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const posCustomerClientSideFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.clientSide, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const updateCustomersFn = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.pos.customersList, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const deleteCustomersFn = (reqBody) => {
  try {
    const response = axiosInstance.delete(API_URLS.pos.customersList, {
      data: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const loyalityActivationFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.loyality, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const websiteOrdersFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.websiteOrders, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const dashboardFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.dashboard, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const floorTablesFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.floorTables, {
      params: reqBody,
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const tableOrdersFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.tableOrders, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const kotPaymentFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.kotPayment, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const getKotDetailFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.tableOrders, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const getKotViewFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.kotViews, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const getQuestionnaireDetails = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.questionnaireDetails, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const changeQuestionOrder = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.pos.changeQuestionOrder, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const bookServiceFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.bookService, reqBody)
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getCounterInfoFn = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.checkCounterAssigned, { params: reqBody })
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getCounterList = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.counterAssignment, { params: reqBody })
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const setCounterFn = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.checkCounterAssigned, reqBody)
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const closeCounter = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.pos.closeCounter, reqBody)
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const addOpeningBalanceService = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.openingBalance, reqBody)
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const cashOutService = (reqBody) => {
  try {
    const response = axiosInstance.put(API_URLS.pos.openingBalance, reqBody)
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const productInvoiceDetailsService = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.productInvoiceDetails, { params: reqBody })
    return response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const posRewardList = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.rewardList, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const sendRedemptionOtpService = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.sendRedemptionOtp, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const verifyRedemptionOtpService = (reqBody) => {
  try {
    const response = axiosInstance.post(API_URLS.pos.verifyRedemptionOtp, reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}
export const payLaterEmiTerms = (reqBody) => {
  try {
    const response = axiosInstance.get(API_URLS.pos.postEmiTerms, { params: reqBody })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

import { API_URLS } from "../../Config/apiUrls"
import axiosInstance from "../../Config/axio.config"

export const loginFn = (redBody) => {
  try {
    const response = axiosInstance.post(API_URLS.login, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const forceLoginFn = (redBody) => {
  try {
    const response = axiosInstance.post(`Api_data/force-login-api-of-bhaaraterp/`, redBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const userSessionFn = () => {
  try {
    const response = axiosInstance.get(`Api_data/user-session-api-of-bhaaraterp/`, {
      params: { ip_address: localStorage.getItem("ip_address") },
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const logoutUserFn = (redBody) => {
  try {
    const response = axiosInstance.post(`Api_data/user-logout-api-of-bhaaraterp/`, redBody, {
      headers: {
        Authorization: localStorage.getItem("customersToken"),
        Token: localStorage.getItem("customersToken")
      }
    })
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

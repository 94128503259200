import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState, useCallback, useRef } from "react";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import CustomDiv from "../../Shared/CustomDiv";
import Text from "../../Shared/Text";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

import classNames from "classnames";
import { Helmet } from "react-helmet";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import { baseURL } from "../../Config/BaseUrl";

export default function EstimatesPdf() {
  const [data, setData] = useState([]);
  const { pdf, storeId } = useParams();

  const targetRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });

  const getData = useCallback(() => {
    axios
      .get(
        `${baseURL}/customer-pannel/customer-estimate-details-api-in-bhaaraterp/?page=1&store_id=${storeId}&estimate_id=${pdf}`,
        {
          headers: {
            authorization: localStorage.getItem("customersToken"),
          },
        }
      )
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pdf, storeId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [checked, setChecked] = useState(true);
  const [email1, setEmail1] = useState([]);

  const handleChange1 = () => {
    setChecked(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const SendOto = () => {
    axios
      .post(`${baseURL}/customer-pannel/get-customer-login-otp-api/`, {
        email_or_mobile: email,
      })
      .then((response) => {
        setEmail1(response.data.email);
        response.data.response_code === 200 && handleChange1();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogin = () => {
    axios
      .post(`${baseURL}/customer-pannel/customer-login-api/`, {
        email: email1,
        key: otp,
      })
      .then((response) => {
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("customersToken", response.data.token);
        localStorage.setItem("customer_name", response.data.customer_name);
        enqueueSnackbar(response.data.message, {
          autoHideDuration: 2000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        response.data.response_code === 200 && window.location.reload();
      });
  };

  const DownloadPDF = () => {
    const capture = document.querySelector("#pdf");
    html2canvas(capture).then((canvas) => {
      const imageData = canvas.toDataURL("img/jpg");
      const doc = new jsPDF("p", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imageData, "JPG", 0, 0, componentWidth, componentHeight);
      doc.save("Estimate.pdf");
    });
  };

  const ShowStatus = (status) => {
    axios
      .post(
        `${baseURL}/customer-pannel/customer-estimate-details-api-in-bhaaraterp/`,
        { store_id: storeId, estimate_id: pdf, estimate_status: status },
        {
          headers: {
            authorization: localStorage.getItem("customersToken"),
          },
        }
      )
      .then((response) => {
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Customers Estimates Pdf Bharat Erp Panel</title>
          <link rel="canonical" href="https://bharaterp.org" />
        </Helmet>
      </div>
      {localStorage.getItem("customersToken") ? (
        <div className="w-full px-2 py-10 md:px-5 lg:py-20 bg-red-50 lg:px-64">
          <div className="w-full px-2 bg-white border-2">
            <div className="ribbon ribbon-top-left lg:!block !hidden">
              <span
                className={classNames(
                  data?.customer_estimate_status === "Pending" &&
                  "bg-yellow-500 border-2 border-yellow-700",
                  data?.customer_estimate_status === "Accepted" &&
                  "bg-green-500 border-2 border-green-700",
                  data?.customer_estimate_status === "Declined" &&
                  "bg-red-500 border-2 border-red-700"
                )}
              >
                {data?.customer_estimate_status}
              </span>
            </div>

            <div className="items-end justify-end py-5 lg:flex">
              <div className="grid items-center justify-center grid-cols-2 gap-5 lg:flex ">
                <div className="w-32 my-3 lg:hidden lg:my-0">
                  <span
                    className={classNames(
                      data?.customer_estimate_status === "Pending" &&
                      "bg-yellow-500 border-2 border-yellow-700 py-2 px-5 font-bold rounded-md",
                      data?.customer_estimate_status === "Accepted" &&
                      "bg-green-500 border-2 border-green-700 py-2 px-5 font-bold rounded-md",
                      data?.customer_estimate_status === "Declined" &&
                      "bg-red-500 border-2 border-red-700 py-2 px-5 font-bold rounded-md"
                    )}
                  >
                    {data?.customer_estimate_status}
                  </span>
                </div>
                <button
                  onClick={handlePrint}
                  className="w-32 px-5 py-2 my-1 font-bold bg-blue-400 border-2 border-blue-700 rounded-md lg:my-0 hover:bg-blue-200"
                >
                  View PDF
                </button>
                <button
                  onClick={DownloadPDF}
                  className="w-32 px-5 py-2 my-1 font-bold bg-green-400 border-2 border-green-700 rounded-md lg:my-0 hover:bg-green-200"
                >
                  Download
                </button>
                {data?.customer_estimate_status === "Pending" ? (
                  <div className="flex gap-5">
                    <button
                      onClick={() => ShowStatus("Declined")}
                      className="w-32 px-5 py-2 my-1 font-bold bg-yellow-400 border-2 border-yellow-700 rounded-md lg:my-0 hover:bg-yellow-200"
                    >
                      Decline
                    </button>
                    <button
                      onClick={() => ShowStatus("Accepted")}
                      className="px-5 py-2 my-1 font-bold bg-green-500 border-2 border-green-700 rounded-md lg:my-0 hover:bg-green-200"
                    >
                      Accepted
                    </button>
                  </div>
                ) : (
                  <div>
                    {data?.customer_estimate_status === "Accepted" && (
                      <button
                        onClick={() => ShowStatus("Declined")}
                        className="px-5 py-2 my-1 font-bold bg-yellow-400 border-2 border-yellow-700 rounded-md lg:my-0 hover:bg-yellow-200"
                      >
                        Decline
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div id="pdf" ref={targetRef} className="w-full h-full px-10 py-5 ">
              <div className="flex flex-col-reverse items-center justify-between border-b-2 md:flex-row">
                <div className="flex flex-col my-5 ">
                  <div className="flex">
                    <p className="font-bold">Company :</p>
                    <p className="px-3">{data?.customer?.company_name}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">Customer :</p>
                    <p className="px-3">
                      {data?.customer?.first_name +
                        " " +
                        data?.customer?.middle_name +
                        " " +
                        data?.customer?.last_name}
                    </p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">Add :</p>
                    <p className="px-3">{data?.customer?.address1}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">City :</p>
                    <p className="px-3">{data?.customer?.city}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">State :</p>
                    <p className="px-3">{data?.customer?.state}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">Country :</p>
                    <p className="px-3">{data?.customer?.nationality}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">PAN :</p>
                    <p className="px-3">{data?.customer?.pincode}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">GSTIN :</p>
                    <p className="px-3">{data?.customer?.gst_number}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">Phone :</p>
                    <p className="px-3">{data?.customer?.mobile_no}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">Email:</p>
                    <p className="px-3">{data?.customer?.email}</p>
                  </div>
                </div>
                <div className="flex flex-col my-2 lg:my-5">
                  <img src={data?.store?.logo} alt="" className="w-32 h-32" />
                  <div className="flex">
                    <p className="font-bold">{data?.store?.store_name}</p>
                    <p className="px-2"></p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">City :</p>
                    <p className="px-2">{data?.store?.city}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">State :</p>
                    <p className="px-2">{data?.store?.state}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">Country :</p>
                    <p className="px-2">{data?.store?.country}</p>
                  </div>

                  <div className="flex">
                    <p className="font-bold">GSTIN :</p>
                    <p className="px-3">{data?.store?.gst_number}</p>
                  </div>
                  <div className="flex">
                    <p className="font-bold">Estimate :</p>
                    <p className="px-2">{data?.estimate_no}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between border-b-2 md:flex-row">
                <div className="flex flex-row truncate">
                  <p className="py-2 font-bold">Place Of Supply :</p>
                  <p className="mt-2 overflow-hidden lg:px-5 lg:py-2 lg:mt-0 text-ellipsis">
                    {data?.customer?.source_of_supply}
                  </p>
                </div>
                <div className="flex flex-row truncate">
                  <p className="py-2 font-bold">Description :</p>
                  <p
                    dangerouslySetInnerHTML={{ __html: data?.notes }}
                    className="px-5 py-2 overflow-hidden text-ellipsis"
                  ></p>
                </div>
              </div>
              <div>
                <div className="my-5 ">
                  <TableContainer className="!shadow-none" component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow className="!bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 ">
                          <TableCell className="!p-1 border-r border-white">
                            #
                          </TableCell>
                          <TableCell
                            className="!p-1 border-r border-white"
                            align="right"
                          >
                            Item
                          </TableCell>
                          <TableCell
                            className="!p-1 border-r border-white"
                            align="right"
                          >
                            HSN/SAC
                          </TableCell>
                          <TableCell
                            className="!p-1 border-r border-white"
                            align="right"
                          >
                            Rate
                          </TableCell>
                          <TableCell
                            className="!p-1 border-r border-white"
                            align="right"
                          >
                            Quantity
                          </TableCell>
                          <TableCell
                            className="!p-1 border-r border-white"
                            align="right"
                          >
                            SGST
                          </TableCell>
                          <TableCell
                            className="!p-1 border-r border-white"
                            align="right"
                          >
                            CGST
                          </TableCell>
                          <TableCell
                            className="!p-1 border-r border-white"
                            align="right"
                          >
                            IGST
                          </TableCell>
                          <TableCell
                            className="!p-1 border-r border-white"
                            align="right"
                          >
                            Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.estimate_item?.map((item) => {
                          return (
                            <TableRow key={item.id}>
                              <TableCell className="!px-3 !py-2 text-xs border-r border-white">
                                {item?.id}
                              </TableCell>
                              <TableCell
                                className="!px-3 !py-2 text-xs border-r border-white"
                                align="right"
                              >
                                {item?.item_name || "-"}
                              </TableCell>
                              <TableCell
                                className="!px-3 !py-2 text-xs border-r border-white"
                                align="right"
                              >
                                {item?.hsn || "-"}
                              </TableCell>
                              <TableCell
                                className="!px-3 !py-2 text-xs border-r border-white"
                                align="right"
                              >
                                {item?.rate}
                              </TableCell>
                              <TableCell
                                className="!px-3 !py-2 text-xs border-r border-white"
                                align="right"
                              >
                                {item?.quantity}
                              </TableCell>
                              {data?.customer?.source_of_supply?.slice(3) ===
                                data?.customer?.state ? (
                                <TableCell
                                  className="!px-3 !py-2 text-xs border-r border-white"
                                  align="right"
                                >
                                  {item?.sgst}
                                </TableCell>
                              ) : (
                                <TableCell
                                  className="!px-3 !py-2 text-xs border-r border-white"
                                  align="right"
                                >
                                  -
                                </TableCell>
                              )}
                              {data?.customer?.source_of_supply?.slice(3) ===
                                data?.customer?.state ? (
                                <TableCell
                                  className="!px-3 !py-2 text-xs border-r border-white"
                                  align="right"
                                >
                                  {item?.cgst}
                                </TableCell>
                              ) : (
                                <TableCell
                                  className="!px-3 !py-2 text-xs border-r border-white"
                                  align="right"
                                >
                                  -
                                </TableCell>
                              )}
                              {data?.customer?.source_of_supply?.slice(3) ===
                                data?.customer?.state ? (
                                <TableCell
                                  className="!px-3 !py-2 text-xs border-r border-white"
                                  align="right"
                                >
                                  -
                                </TableCell>
                              ) : (
                                <TableCell
                                  className="!px-3 !py-2 text-xs border-r border-white"
                                  align="right"
                                >
                                  {item?.igst}
                                </TableCell>
                              )}

                              <TableCell
                                className="!px-3 !py-2 text-xs border-r border-white"
                                align="right"
                              >
                                {item?.total_amount}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div className="flex flex-row items-center justify-between w-full my-5 border-b-2 lg:flex">
                  <div className="flex flex-col lg:w-[50%] w-full">
                    <p className="my-3 font-bold ">Scan UPI for payment</p>
                    <img
                      src={data?.store?.store_qr_code_image}
                      alt=""
                      className="h-28 w-28"
                    />
                  </div>
                  <div className="flex flex-col lg:w-[50%] w-full my-3">
                    <div className="flex flex-row items-center justify-between w-full my-2 border-b-2">
                      <p>Sub Total</p>
                      <p>
                        {data?.total_price} {data?.store?.currency}
                      </p>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full my-2 border-b-2">
                      <p>Discount</p>
                      <p>{data?.discount} </p>
                    </div>
                    {data?.customer?.source_of_supply?.slice(3) ===
                      data?.customer?.state ? (
                      <>
                        <div className="flex flex-row items-center justify-between w-full my-2 border-b-2">
                          <p>CGST</p>
                          <p>{data?.cgst} </p>
                        </div>
                        <div className="flex flex-row items-center justify-between w-full my-2 border-b-2">
                          <p>SGST</p>
                          <p>{data?.sgst} </p>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-row items-center justify-between w-full my-2 border-b-2">
                        <p>IGST</p>
                        <p>{data?.igst} </p>
                      </div>
                    )}

                    <div className="flex flex-row items-center justify-between w-full my-2 ">
                      <p className="font-bold">Total</p>
                      <p>
                        {data?.total_amount} {data?.store?.currency}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-between w-full my-5 md:flex-row lg:flex">
                  <div className="flex flex-col w-full">
                    <p className="font-bold">Authorised Signature</p>
                    <img
                      src={data?.store?.signature}
                      alt=""
                      className="w-32 h-32"
                    />
                    <p className="font-bold">Terms and Conditions :</p>
                    <p>Pay according to Terms and conditions.</p>
                  </div>
                  <div className="flex flex-col w-full my-5 lg:items-end lg:my-0">
                    <p className="my-2 font-bold">
                      Bank Details are Given Below :
                    </p>
                    <div className="flex flex-row my-2">
                      <p className="font-bold">Bank name :</p>
                      <p className="px-2">{data?.store?.bank_name}</p>
                    </div>
                    <div className="flex flex-row my-2">
                      <p className="font-bold">Bank A/c. No. :</p>
                      <p className="px-2">{data?.store?.account_no}</p>
                    </div>
                    <div className="flex flex-row my-2">
                      <p className="font-bold">IFSC Code :</p>
                      <p className="px-2">{data?.store?.IFSC}</p>
                    </div>
                    <div className="flex flex-row my-2">
                      <p className="font-bold">Swift Code :</p>
                      <p className="px-2">{data?.store?.swift_code}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <CustomDiv
            className="!rounded-none h-screen w-screen center justify-center items-start"
            elevation={0}
          >
            <CustomDiv
              className="!rounded !lg:bg-gray-200 lg:h-3/4 lg:w-2/3 lg:flex lg:px-0 px-10"
              elevation={0}
            >
              <CustomDiv
                elevation={0}
                className="center lg:!block !hidden !rounded-none lg:w-1/2 h-full py-10 "
              >
                <img
                  src="https://aaraerp.s3.ap-south-1.amazonaws.com/static/logos/Group-481461.png"
                  alt=""
                  className="lg:!block !hidden"
                />
              </CustomDiv>
              <CustomDiv
                elevation={0}
                className="lg:w-1/2  !rounded-none center flex-col gap-4 my-5 lg:my-0"
              >
                <div className="px-10 lg:px-0">
                  <img
                    src="https://bharaterp.org/static/media/logobhaarat-5-1.ce616576c55f24456583.png"
                    alt=""
                    className="mb-5 lg:h-10 lg:w-40"
                  />
                </div>
                <Text className="text-2xl border-b-2 cursor-pointer">
                  <span className="font-semibold text-center text-blue-500">
                    BhaartErp
                  </span>
                  Customers Panel
                </Text>
                <Text className="mt-5 text-5xl font-semibold lg:text-3xl">
                  Sign In
                </Text>

                {checked ? (
                  <>
                    <form onSubmit={SendOto}>
                      <TextField
                        label="Email/Number"
                        type="text"
                        id="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        className="w-full py-5"
                      />
                      <Button
                        variant="contained"
                        size="large"
                        type="submit"
                        className="!rounded w-full !capitalize !my-5"
                        disableElevation
                      >
                        Send
                      </Button>
                    </form>
                  </>
                ) : (
                  <>
                    <form onSubmit={handleLogin}>
                      <TextField
                        label="Otp"
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="!w-full"
                      />
                      <Button
                        variant="contained"
                        size="large"
                        className="!rounded w-full !capitalize !my-5"
                        disableElevation
                      >
                        Sign In
                      </Button>
                    </form>
                  </>
                )}
                <Text>
                  Don't you have any acount?
                  <Link
                    className="pl-2 text-blue-400 underline"
                    onClick={() => setChecked(false)}
                  >
                    Create One
                  </Link>
                </Text>
              </CustomDiv>
            </CustomDiv>
          </CustomDiv>
        </>
      )}
    </div>
  );
}

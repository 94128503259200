import { Add, Close } from "@mui/icons-material";
import { Button, Divider, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import axiosInstance, { baseURL } from "../../../Config/BaseUrl";
import CustomSelect from "../../CustomSelect";
import CustomButton from "../../CustomButton";
import CustomInputField from "../../FormInputs/CustomInputField";
import SearchAndSelect from "../../SearchAndSelect";
import CustomInput from "../../CustomInput";
import CustomModal from "../../CustomModal";
import axios from "axios";

const CreateTicketFromItem = ({ productId, status }) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [engineerList, setEngineerList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [serviceManager, setServiceManager] = useState([]);
  const [customerInsideImage, setCustomerInsideImage] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [ticketService, setTicketService] = useState({});
  const [store, setStore] = useState([]);
  const [storeId, setStoreId] = useState();

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen(true);
  };
  const handleCloseForm = () => {
    setFile("");
    formik?.resetForm();
    handleClose();
  };
  const initialValues = {
    service_manager_id: "",
    service_id: "",
    issue: "",
    remarks: "",
    customer_name: "",
    service_engineer_id: "",
    customer_mobile: "",
    customer_city: "",
    customer_address: "",
    urgent_status: false,
    service_charge: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {
      const formData = new FormData();
      formData.append("service_manager_id", formik.values.service_manager_id);
      formData.append("service_id", formik.values.service_id);
      formData.append("service_engineer_id", formik.values.service_engineer_id);
      formData.append("issue", formik.values.issue);
      formData.append("remarks", formik.values.remarks);
      // formData.append("customer_name", formik.values.customer_name);
      // formData.append("customer_mobile", formik.values.customer_mobile);
      // formData.append("customer_city", formik.values.customer_city);
      // formData.append("customer_address", formik.values.customer_address);
      formData.append("urgent_status", formik.values.urgent_status);
      formData.append("service_charge", formik.values.service_charge);
      formData.append("store_id", Number(storeId));
      try {
        axiosInstance
          .post(
            `/customer-pannel/customer-service-manager-booking-api-bhaaraterp/`,
            formData
          )
          .then((res) => {
            if (res.data.response_code === 200) {
              toast.success(res.data.message);
              handleClose();
              setCustomerInsideImage();
              formik.resetForm();
            }
            toast.success(res.data.message);
          })
          .catch((error) => console.log(error));
      } catch {
        console.error("Getting Problem to fetch Api!!!");
      }
      // axiosInstance.post(`/support_page/add-customer-book-service-ticket-api-of-bhaaraterp/`, formData).then((res) => {
    },
  });
  // const SubmitForm = () => {}
  const handleSelectRow = (id) => {
    setSelectedIds((prevSelectedRow) =>
      prevSelectedRow.includes(id)
        ? prevSelectedRow.filter((i) => i !== id)
        : [...prevSelectedRow, id]
    );
  };

  useEffect(() => {
    const getStore = () => {
      axios
        .get(
          `${baseURL}/customer-pannel/customer-store-list-api-in-bhaaraterp/`,
          {
            headers: {
              authorization: localStorage.getItem("customersToken"),
            },
          }
        )
        .then((response) => {
          setStore(response?.data?.store_list);
          open === true &&
            setStoreId(response?.data?.store_list?.[0]?.store_id);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    open === true && getStore();
  }, [open]);

  const createSErviceList = () => {
    axiosInstance
      .get(
        `/support_page/add-customer-book-service-ticket-api-of-bhaaraterp/?book_service_id=${productId}`
      )
      .then((res) => {
        setTicketService(res.data.data);
      });
  };
  useEffect(() => {
    productId && createSErviceList();
  }, [productId]);

  // const ServiceListFn = async () => {
  //   try {
  //     const res = await axiosInstance.get(`leads/lead-data-api-of-bhaaraterp/`)
  //     setServiceList(res.data?.data?.map(({ name, id }) => ({ label: name, value: id })))
  //   } catch (error) {
  //     console.error("Error fetching lead data:", error)
  //   }
  // }
  // const EngineerListFn = async () => {
  //   try {
  //     const res = await axiosInstance.get(`leads/lead-data-api-of-bhaaraterp/`)
  //     setEngineerList(res.data?.data?.map(({ name, id }) => ({ label: name, value: id })))
  //   } catch (error) {
  //     console.error("Error fetching lead data:", error)
  //   }
  // }
  const ServiceDetailsListFn = async () => {
    try {
      const res = await axiosInstance.get(
        `/customer-pannel/customer-service-manager-booking-api-bhaaraterp/`,
        { params: { store_id: storeId } }
      );
      setEngineerList(
        res.data?.service_engineers?.map(({ name, emp_id }) => ({
          label: name,
          value: emp_id,
        }))
      );
      setServiceList(
        res.data?.services?.map(({ service_name, id, price }) => ({
          label: service_name,
          value: id,
          value2: price,
        }))
      );
      setServiceManager(
        res.data?.service_manager?.map(({ name, emp_id }) => ({
          label: name,
          value: emp_id,
        }))
      );
    } catch (error) {
      console.error("Error fetching lead data:", error);
    }
  };

  useEffect(() => {
    storeId && ServiceDetailsListFn();
  }, [storeId]);
  const handleServiceChange = (value) => {
    const selectedService = serviceList.find(
      (service) => service.value === value
    );
    if (selectedService) {
      formik.setFieldValue("service_id", selectedService.value);
      formik.setFieldValue("service_charge", selectedService.value2); // Set the service charge based on the selected service
    }
  };
  return (
    <>
      {status === true ? (
        <Button
          // disableElevation
          size="small"
          startIcon={<Add />}
          color="warning"
          variant="contained"
          className="!h-10 !px-1 md:!px-2 lg:!px-3"
          onClick={handleClick}
        >
          <span className="hidden mr-1 md:flex">Create</span>Ticket
        </Button>
      ) : (
        <CustomButton
          disableElevation
          startIcon={<Add />}
          className="!w-40 !ml-5 !text-center Vender-Datails"
          // color="primary"
          variant="contained"
          onClick={handleClick}
        >
          <span className="hidden mr-1 md:flex">Create</span> Ticket
        </CustomButton>
      )}
      <CustomModal open={open} onClose={handleClose} className="!h-[85vh] w-[350px] md:w-[450px]">
        <div className="flex justify-between px-4 h-fit">
          <div></div>
          <h3 className="my-1 text-xl font-bold">Create Ticket</h3>
          <Close
            onClick={handleClose}
            className="text-red-600 p-0.5 border border-red-500 rounded-full cursor-pointer"
          />
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="w-full p-2 font-poppins"
        >
          <Divider />
          <div className="flex flex-col gap-3 pt-2 ">
            <div className="flex overflow-auto h-[65vh] hide-scroll flex-col gap-4">
              <div className="grid grid-cols-2 gap-3 !text-xs lg:text-lg">
                <CustomSelect
                  id="Select Store"
                  label="Store"
                  placeholder="Select Urgent Status"

                  value={storeId}
                  formik={formik}
                >
                  {store?.map((item) => (
                    <MenuItem
                      key={item.store_id}
                      value={item.store_id}
                      className="text-sm"
                    >
                      {item.store_name}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value="false">False</MenuItem> */}
                </CustomSelect>
                <SearchAndSelect
                  name="service_id"
                  id="service_id"
                  options={serviceList}
                  // formik={formik}
                  label="Select Service"
                  setValue={(value) => {
                    handleServiceChange(value);
                  }}
                  value={formik.values.service_id}
                // onChange={(e) => {
                //   console.log("BBBBBB", e)
                //   handleServiceChange(e)
                // }}
                />
                <SearchAndSelect
                  name="service_engineer_id"
                  options={engineerList}
                  formik={formik}
                  label="Select Engineer"
                />
                <SearchAndSelect
                  name="service_manager_id"
                  options={serviceManager}
                  formik={formik}
                  label="Select Service Manager"
                />
                {/* <CustomInput
                  id="customer_name"
                  formik={formik}
                  label="Customer Name"
                  placeholder="Enter Customer Name"
                />

                <CustomInput
                  type="number"
                  id="customer_mobile"
                  formik={formik}
                  label="Customer Mobile"
                  placeholder="Enter Customer Mobile"
                />
                <CustomInput
                  id="customer_city"
                  label="City"
                  formik={formik}
                  placeholder="Enter Customer City"
                /> */}
                <CustomSelect
                  id="urgent_status"
                  label="Urgent Status"
                  placeholder="Select Urgent Status"
                  formik={formik}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </CustomSelect>
                <CustomInput
                  // disabled
                  type="number"
                  id="service_charge"
                  formik={formik}
                  value={formik?.values?.service_charge}
                  label="Service Charge"
                  placeholder="0.0"
                />
              </div>
              {/* <CustomInput
                required
                id="customer_address"
                formik={formik}
                rows="2"
                multiline={true}
                label="Customer Address"
                placeholder="Enter Customer Address"
              /> */}
              <CustomInput
                required
                id="issue"
                formik={formik}
                rows="2"
                multiline={true}
                label="Customer Product Issues"
                placeholder="Enter Issues"
              />

              <CustomInput
                required
                id="remarks"
                rows="3"
                multiline={true}
                formik={formik}
                placeholder="Enter Remarks Here..."
                label="Remarks"
              />
            </div>
            <Divider />
            <div className="flex justify-end gap-4">
              <Button variant="outlined" onClick={handleCloseForm}>
                Close
              </Button>
              <Button
                variant="contained"
                className=""
                color="primary"
                type="submit"
              >
                {" "}
                Submit
              </Button>
            </div>
          </div>
        </form>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default CreateTicketFromItem;

import axios from "axios"
import { baseURL } from "../../Config/BaseUrl"
import { API_URLS } from "../../Config/apiUrls"

export const LoginFn = (email) => {


    try {
        const response = axios.post(baseURL + API_URLS.get_customer_login_otp_api, {

            email_or_mobile: email,
        })



        return response || []

    }
    catch (e) {



        console.log(e)
    }





}
import { LockOpen } from "@mui/icons-material"
import { Button, Dialog, Divider } from "@mui/material"
// import { API_URLS } from "Config/apiUrls"
// import axiosInstance from "Config/axio.config"
import SupportOn from "../../Resources/SupportOn"
import apple from "../../Resources/apple.png"
import loader from "../../Resources/bharaterp.gif"
import google from "../../Resources/google.png"
import windows from "../../Resources/windows.png"
import safari from "../../Assets/Safari_browser_logo.svg.png"
import chrome from "../../Assets/Google_Chrome_icon_(February_2022).svg.png"
import firefox from "../../Assets/Firefox_logo,_2019.svg.png"



import axios from "axios"
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import CustomInput from "../../Shared/CustomInput"
import CustomButton from "../../Shared/CustomButton"
import ToogleSwitch from "../../Shared/ToogleSwitch"
import { LoginFn } from "../../Services/SignIn"
import { baseURL } from "../../Config/BaseUrl"
import { useSnackbar } from "notistack"
import { postFn } from "../../Services"


export const handleSetLocalStorage = (res, ip_address) => {
  localStorage.setItem("token", res?.token)
  localStorage.setItem("store", res?.store)
  localStorage.setItem("user_id", res?.user_id)
  localStorage.setItem("email", res?.email)
  localStorage.setItem("user_name", res?.user_name)
  localStorage.setItem("store_name", res?.store_name)
  localStorage.setItem("store_logo", res?.store_logo)
  localStorage.setItem("ExpDate", res?.license_expire_date)
  localStorage.setItem("employee_id", res?.employee_id)
  localStorage.setItem("currency", res?.currency)
  localStorage.setItem("currency_symbol", res?.symbol)
  localStorage.setItem("role", res?.role)
  localStorage.setItem("two_step", res?.two_step_verification_enable)
  localStorage.setItem("currency", res?.currency)
  localStorage.setItem("manage_product_variant", res?.manage_product_variant)
  localStorage.setItem("ip_address", ip_address)
  localStorage.setItem("tax_type", res?.tax_type)
  localStorage.setItem("module", JSON.stringify(res?.data?.module))
}

// export const HRM_roles = [
//   "Engineer",
//   "Help Desk",
//   "HR Associate",
//   "HR Executive",
//   "HR Head",
//   "HR Manager",
//   "QA",
//   "Interviewer",
//   "Project Manager",
// ]
// export const CRM_roles = ["Sales Associate", "Sales Engineer", "Sales Executive", "Sales Head", "Sales Manager"]

// export const Inventory_roles = ["Inventory Associate", "Inventory Executive", "Inventory Manager"]

const SignIn = () => {
  const [logInMethod, setLoginMethod] = useState("")
  const [mobile, setMobile] = useState("")
  const [otp, setOtp] = useState("")
  const [responseCode, setResponseCode] = useState(false)
  const client = useQueryClient()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [ipAddress, setIpAddress] = useState(null)











  const [email, setEmail] = useState("");
  const [key, setKey] = useState([]);

  const [checked, setChecked] = useState(true);
  const [email1, setEmail1] = useState([]);
  const [loder, setLoder] = useState(false);

  const { enqueueSnackbar } = useSnackbar();



  const { mutate: logIn } = useMutation(LoginFn, {
    onSuccess: ({ data }) => {

      if (data.response_code === 200) {
        toast.success(data?.message)

        // setResponseCode(data?.response_code)
      }


      else {
        toast.error(data?.message)
      }

    },
  })







  useEffect(
    () => {
      localStorage.getItem("role") === "reseller"
        ? navigate("/resellers/dashboard")
        : navigate("/");
    },
    // eslint-disable-next-line
    []
  );

  // const { mutate: forceLogin } = useMutation(forceLoginFn, {
  //   onSuccess: ({ data }) => {
  //     toast.success(data.message)
  //     if (data?.response_code === 200) {
  //       if (data?.two_step_verification_enable === "Disable") {
  //         handleSetLocalStorage(data, ipAddress)
  //         client.refetchQueries("backgrounds")
  //         client.refetchQueries("usePermission")
  //         navigateToRole(data?.role)
  //       } else {
  //         navigate("/verification", { state: { token: data } })
  //       }
  //     } else if (data?.response_code === 202) {
  //       setOpen(true)
  //     }
  //   },
  // })

  const initialValues = {
    email: "",
    otp: ""

  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {

      console.log(">>>>>>>>>>>>", reqBody)

      console.log("wureretu", values)
    },


  })



  const reqBody = { username: formik.values.email, password: formik.values.password, ip_address: ipAddress }
  console.log("ghfhfssxfj", initialValues)

  const handleSentOTP1 = async () => {







    logIn(mobile)
    // try {
    //   if (responseCode === 200) {
    //     const response = await axiosInstance.post(API_URLS.otpVerify, {
    //       mobile,
    //       otp,
    //     })
    //     handleResponse(response.data)
    //   } else {
    //     const response = await axiosInstance.get(API_URLS.otpVerify, {
    //       params: { mobile },
    //     })
    //     if (response.data.response_code === 200) {
    //       toast.success(response.data.message)
    //       setResponseCode(response?.data.response_code)
    //     } else {
    //       toast.error(response.data.message)
    //     }
    //   }
    // } catch (error) {
    //   console.error("Error:", error)
    // }
  }

  // const handleResponse = (data) => {
  //   if (data.response_code === 200) {
  //     toast.success(data.message)
  //     if (data.two_step_verification_enable === "Enable") {
  //       navigate("/verification", { state: { token: data } })
  //     } else {
  //       handleSetLocalStorage(data, ipAddress)
  //       navigateToRole(data.role)
  //     }
  //   } else {
  //     toast.error(data.message)
  //   }
  // }

  // useEffect(() => {
  //   const fetchIpAddress = async () => {
  //     try {
  //       const response = await axios.get("https://api.ipify.org?format=json")
  //       setIpAddress(response.data.ip)
  //     } catch (error) {
  //       console.error("Error fetching IP address:", error)
  //     }
  //   }
  //   fetchIpAddress()
  // }, [])

  const navigateToRole = (role) => {
    if (role === "Store Admin") {
      navigate("/dashboard")
    } else {
      navigate("/hrm")
    }
  }














  const handleSentOTP = (event) => {
    event.preventDefault();
    axios
      .post(`${baseURL}/customer-pannel/get-customer-login-otp-api/`, {
        email_or_mobile: mobile,
      })
      .then((response) => {



        console.log("gdwujtduyfd", response?.data?.response_code)


        setResponseCode(response?.data?.response_code)
        setKey(response.data.otp_key);
        setEmail1(response.data.email);

        // setResponseCode(response?.response_code)
        response.data.response_code === 200 && setChecked(false);
        enqueueSnackbar(response.data.message, {
          autoHideDuration: 2000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        setEmail("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useQuery(
    ["verifyUser"],
    () => postFn({}, "/customer-pannel/verify-customer-token-api/"),
    {
      onSuccess: (res) => {
        if (res.data.response_code === 200) {
          enqueueSnackbar(res.data.message, {
            autoHideDuration: 2000,
            variant: "success",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          });
          navigate("/home");
        }
      },
      onError: () => {
        localStorage.removeItem("customersToken");
      },
      retry: 0,
      enabled: Boolean(localStorage.getItem("customersToken")),
    }
  );

  const handleLogin = (event) => {
    setLoder(true);
    event.preventDefault();
    const fd = new FormData();
    fd.append(`${key}`, otp);
    fd.append("email", mobile);
    axios
      .post(`${baseURL}/customer-pannel/customer-login-api/`, fd, {})
      .then((response) => {
        localStorage.setItem("email", response.data.email);
        localStorage.setItem("customersToken", response.data.token);
        localStorage.setItem("customer_name", response.data.customer_name);
        enqueueSnackbar(response.data.message, {
          autoHideDuration: 2000,
          variant: "success",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        response.data.response_code === 200 && navigate("/home");
        setLoder(false);
      });
  };

  console.log("reeeeeeeeee", responseCode);
  return (
    <>
      <div className="flex flex-col justify-between h-screen overflow-hidden">
        <div className="flex flex-col items-center justify-between px-10 py-5 lg:flex-row ">
          <img src={loader} alt="" className="hidden w-1/3 h-auto pt-5 lg:block" />
          <div className="items-end hidden w-40 gap-1 p-2 my-auto text-center items whitespace-nowrap lg:grid">
            <Divider className="!w-52 !font-semibold">Available On</Divider>
            <img src={google} alt="" className="w-11/12" />
            <img src={apple} alt="" className="w-11/12" />
            <img src={windows} alt="" className="w-11/12" />
            <Divider className="!w-52 !font-semibold">Support On</Divider>
            <SupportOn />
          </div>
          <form onSubmit={formik.handleSubmit} className="flex flex-col justify-center   gap-5 lg:w-[25%] w-full lg:p-5 p-2 lg:mt-36 mt-32 text-lg">
            <span className="flex justify-center">
              <img src="https://bhaaraterp.s3.ap-south-1.amazonaws.com/bharaterplogo.webp" alt="" className="!w-5/6" />
            </span>
            {/* 
            <div className="flex gap-2 font-semibold text-center">
              <ToogleSwitch label="Login With" onChange={(event) => setLoginMethod(event.target.checked)} />
            </div> */}

            <>
              {responseCode === 200 ? (
                <CustomInput
                  label="OTP"
                  type="number"
                  value={otp}
                  onChange={(event) => setOtp(event.target.value)}
                  placeholder="Enter OTP"
                />
              ) : (
                <CustomInput
                  type="text"
                  value={mobile}
                  label="Mobile Number/Email"
                  onChange={(event) => setMobile(event.target.value)}
                  placeholder="Enter Mobile Number/Email"
                />
              )}
            </>






            {(responseCode === 200 ?
              (<CustomButton
                onClick={handleLogin}
                type="button"
                className="!bg-gradient-to-r !text-white !from-pink-500 !to-[#6D24AD]"
              >
                Login

              </CustomButton>) :



              (<CustomButton
                onClick={handleSentOTP}
                type="button"
                className="!bg-gradient-to-r !text-white !from-pink-500 !to-[#6D24AD]"


              >

                Send OTP

              </CustomButton>)
            )}

            <div className="flex items-center justify-between">
              <Link target="_blank" to="https://bhaaraterp.com/">
                <CustomButton className="!bg-gradient-to-r !text-white !from-pink-500 !to-[#6D24AD]">
                  Sign Up
                </CustomButton>
              </Link>

              <button
                className="text-lg text-center text-blue-500 underline"
                onClick={() => navigate("/forget-password")}
              >
                Forget Password
              </button>
            </div>
          </form>
        </div>




        <div className="grid items-end w-full gap-1 p-2 px-10 my-auto text-center items whitespace-nowrap md:hidden">
          <Divider className="!w-[100%] !font-semibold ">Available On</Divider>
          <div className="flex flex-wrap mx-auto">  <img src={google} alt="" className="w-20 " />
            <img src={apple} alt="" className="w-20" />
            <img src={windows} alt="" className="w-20" /></div>
          <Divider className="!w-full !font-semibold">Support On</Divider>
          <div className="flex items-center justify-center w-full gap-3 p-1 h-fit">

            <img src={safari} alt="" className="w-10" />
            <img src={chrome} alt="" className="w-10 " />
            <img src={firefox} alt="" className="w-10" />


          </div>
        </div>

        <div className="hidden md:block lg:mt-0 !bg-[url('https://pub-dd92b94041b245f2b574511ea9df9444.r2.dev/ezyzip-images/roadandstores.webp')] bg-cover overflow-hidden z-50 w-full h-64 no-repeat bg-center bg-no-repeat relative bottom-0">
          <div className="!bg-[url('https://bhaaraterp.s3.ap-south-1.amazonaws.com/anim2.png')] w-32 h-20 absolute bottom-6 right-[10%] bg-no-repeat myn1 bg-[length:70px]"></div>
          <div className="!bg-[url('https://bhaaraterp.s3.ap-south-1.amazonaws.com/anim1.png')] w-64 h-24 absolute bottom-[-21px] right-3 bg-no-repeat myn2 bg-[length:138px]"></div>
        </div>
        <div className="p-1 font-semibold text-center text-pink-700 bg-gray-200 animate-pulse">
          © 2024 Bharat ERP. All rights reserved.
        </div>
        <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ className: "w-[500px]" }}>
          <div className="flex flex-col gap-2 p-3">
            <div className="flex flex-col items-center gap-2 text-center">
              <LockOpen className="!text-7xl" />
              <p className="text-xl font-semibold">You are already logged in on another device!</p>
              <p>Do you want to log out on that device?</p>
            </div>
            <div className="flex justify-end gap-2 py-2">
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button variant="contained"
              // onClick={() => forceLogin(reqBody)}
              >
                Log Out
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  )
}

export default SignIn
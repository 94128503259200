import { DarkMode, LightMode, Menu, ExitToApp, Home, Ballot, Description, Subscriptions, Payments, History } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import React from "react";
import Customers from "../../Pages/Customers";
import Estimates from "../../Pages/Estimates";
import Invoices from "../../Pages/Invoices";
import Subscriptons from "../../Pages/Subscriptions";
import ProfileMenu from "../ProfileMenu";
import Text from "../Text";
import { Link } from "react-router-dom";
import BookHistory from "../../Pages/BookHistory";
import CreateTicketFromItem from "./CreateTicket";
import TicketHistory from "../../Pages/TicketHistory";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabData = [
  { label: "Home", component: <Customers />, icon: <Home /> },
  { label: "Estimates", component: <Estimates />, icon: <Ballot /> },
  { label: "Invoices", component: <Invoices />, icon: <Description /> },
  { label: "Subscriptions", component: <Subscriptons />, icon: <Subscriptions /> },
  { label: "Booking History", component: <BookHistory />, icon: <History /> },
  { label: "Ticket History", component: <TicketHistory />, icon: <Payments /> },
];

const Header = ({ setTheme, theme, onLogout }) => {
  const [value, setValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleTheme = () => {
    setTheme(!theme);
    localStorage.setItem("theme", !theme);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const themeMaterial = useTheme();
  const isSmallScreen = useMediaQuery(themeMaterial.breakpoints.down("md"));
  const handleTabClick = (index) => {
    setValue(index);
  };


  return (
    <>
      <Box
        component={Paper}
        elevation={2}
        className="sticky top-0 z-50 flex-col justify-center w-full lg:flex"
      >
        <div className="flex items-center justify-between px-5">
          <img
            alt="logo"
            src="https://bhaaraterp.s3.ap-south-1.amazonaws.com/bharaterplogo.webp"
            className="h-16 my-5  w-52"
          />
          <div className="flex flex-col gap-1 mt-2 md:mt-5 md:flex-row">
            <div className="hidden lg:block">
              <CreateTicketFromItem status={true} />
            </div>
            <Link to="https://bharaterp.org/" className="">

              <p className="px-1.5 md:px-2.5 lg:px-5 py-2 font-bold bg-purple-400 flex rounded-md hover:bg-violet-400 hover:underline hover:text-white">
                <span className="hidden mr-1 md:flex">Visit</span> Website
              </p>
            </Link>
          </div>
        </div>

        <span className="flex p-2 !px-4 justify-between items-center w-full">
          {isSmallScreen && (
            <IconButton
              onClick={handleDrawerOpen}
              className="!block lg:!hidden "
            >
              <Menu />
            </IconButton>
          )}
          <Text className="text-xl text-center cursor-pointer md:text-2xl">
            <span className="text-blue-500">BharatErp </span>Customers Panel
          </Text>
          <span className="flex items-center gap-3">
            <ProfileMenu onLogout={onLogout} />
            <IconButton
              onClick={toggleTheme}
              className="!border-solid !border !border-black !border-opacity-50 !hidden lg:!block h-10 w-10"
            >
              {theme ? (
                <LightMode color="warning" />
              ) : (
                <DarkMode color="action" />
              )}
            </IconButton>
          </span>
        </span>

        {!isSmallScreen && (
          <Tabs
            value={value}
            onChange={handleChange}
            className=""
            variant="scrollable"
            TabIndicatorProps={{
              className: "!h-[3px]",
            }}
            classes={{
              flexContainer: "!jusify-center",
            }}
            aria-label="basic tabs example"
          >
            {tabData.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                {...a11yProps(index)}
                className="!capitalize"
              />
            ))}
          </Tabs>
        )}
      </Box>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {

            width: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <ListItem >
          <img
            alt="logo"
            src="https://bhaaraterp.s3.ap-south-1.amazonaws.com/bharaterplogo.webp"
            className="w-40 h-16 my-5"
          />
        </ListItem>
        <Divider className="bg-gray-400" />
        <List sx={{ flexGrow: 1 }}>
          {tabData.map((tab, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                setValue(index);
                handleDrawerClose();
              }}
            > <div className="flex gap-3">
                <ListItemText primary={tab.icon} className="text-purple-700" />
                <ListItemText primary={tab.label} />
              </div>
            </ListItem>
          ))}
        </List>

        {/* Logout Button - Positioned at the bottom */}
        <ListItem button onClick={onLogout} sx={{ position: "absolute", bottom: "30px", left: 0, width: "100%", textAlign: "center", }} className="!bg-purple-500  p-3 flex justify-center items-center">
          <ListItemText primary="Logout" />
          <ExitToApp />
        </ListItem>
      </Drawer>

      <div>
        {tabData.map((tab, index) => (
          <TabPanel value={value} index={index} key={index}>
            {tab.component}
          </TabPanel>
        ))}
      </div>
      <div className="fixed left-0 right-0 z-auto flex items-end justify-end p-4 bottom-10 lg:hidden">
        <CreateTicketFromItem status={true} className="!w-20 !h-20" />
      </div>

      <div className="p-1">
        <div className="flex flex-col h-full">



          <div className="fixed bottom-0 left-0 right-0 z-50 flex items-center justify-between gap-3 p-3 bg-white shadow-lg md:hidden">
            {tabData.map((tab, index) => (
              <div
                key={index}
                onClick={() => handleTabClick(index)}
                className="cursor-pointer"
              >
                <div className="flex flex-col items-center">
                  <div className="text-purple-700">{tab.icon}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

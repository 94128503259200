import { API_URLS } from "../../../Config/apiUrls"
import axiosInstance from "../../../Config/axio.config"

export const storeDetails = () => {
  try {
    const response = axiosInstance.get(API_URLS.store.details)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const checkInCheckOutInDetailFn = (reqBody) => {
  try {
    const response = axiosInstance.get("hrm/add-own-employee-attendance-api-of-bhaaraterp/", reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

export const checkInCheckOutInFn = (reqBody) => {
  try {
    const response = axiosInstance.post("hrm/add-own-employee-attendance-api-of-bhaaraterp/", reqBody)
    return response
  } catch ({ error }) {
    throw new Error(error.message)
  }
}

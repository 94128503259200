import React, { useState } from "react";
import { useFormik } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button } from "@mui/material";
import { MonetizationOn } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import CustomModal from "../../../Shared/CustomModal";
import { getFn, postFn } from "../../../Services";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const LoyalityAccount = ({
  customer_id,
  isActivated,
  total_loyality_point,
  store_id,
}) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const client = useQueryClient();
  const handleClose = () => {
    setOpen(false);
    formik.handleReset();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { mutate: loyalityActivation } = useMutation(
    (reqBody) =>
      postFn(reqBody, "/customer-pannel/customer-loyalty-account-api/"),
    {
      onSuccess: ({ data }) => {
        if (data.response_code === 200) {
          console.log(data);
          enqueueSnackbar(data.message, {
            variant: "success",
          });
          client.refetchQueries("loyaltyData");
          handleClose();
        } else {
          enqueueSnackbar(data.message, {
            variant: "error",
          });
        }
      },
    }
  );

  const initialValues = {
    loyalty_id: "",
    account_type: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      loyalityActivation({
        customer_id,
        store_id,
        ...formik.values,
      });
    },
  });

  const { data: loyaltyOptions } = useQuery(
    ["loyalityPoint", formik.values.account_type, store_id],
    () =>
      getFn(
        { loyalty_type: formik.values.account_type, store_id },
        "/customer-pannel/customer-store-loyalty-type-api/"
      ),
    {
      refetchOnWindowFocus: false,
      select: (data) =>
        data?.data?.data.map((item) => {
          return { label: item.title, value: item.id };
        }),
      enabled: formik.values.account_type === "Card",
    }
  );

  return (
    <>
      {isActivated ? (
        <span className="flex items-center gap-3 text-sm font-bold text-orange-500 md:text-lg">
          <MonetizationOn className="!text-orange-500" /> Loyalty Points :{" "}
          {total_loyality_point}
        </span>
      ) : (
        <Button variant="outlined" onClick={handleOpen}>
          <span>
            <MonetizationOnIcon />
            Loyalty
          </span>{" "}
          <span>Activate Loyalty Account</span>
        </Button>
      )}
      <CustomModal
        className="lg:!w-[60%] w-[80%]"
        title="Loyalty Activation"
        open={open}
        onClose={handleClose}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="grid gap-3 p-4">
            <p className="font-semibold">Account Type</p>
            <select
              placeholder="Select Account Type"
              label="Account Type"
              className="p-2 border border-gray-600 rounded"
              onChange={(e) =>
                formik.setFieldValue("account_type", e.target.value)
              }
            >
              <option className="p-2 border-none" value={0}>
                Select Account Type
              </option>
              {[
                { value: "Card", label: "Card" },
                { value: "Percentage", label: "Percentage" },
              ].map(({ value, label }, idx) => {
                return (
                  <option className="p-2 border-none" key={idx} value={value}>
                    {label}
                  </option>
                );
              })}
            </select>
            {formik.values.account_type === "Card" ? (
              <div className="flex flex-col gap-2">
                <p className="font-semibold">Loyalty Card Type Name</p>
                <select
                  className="p-2 border border-gray-600 rounded"
                  placeholder="Select Loyalty Type"
                  onChange={(e) =>
                    formik.setFieldValue("loyalty_id", e.target.value)
                  }
                >
                  <option className="p-2 border-none" value={0}>
                    Select Loyalty Type
                  </option>
                  {loyaltyOptions?.map(({ value, label }, idx) => {
                    return (
                      <option
                        className="p-2 border-none"
                        key={idx}
                        value={value}
                      >
                        {label}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
            <Button
              variant="lg:!outlined"
              className="!w-fit !mx-auto !text-gray-600 !border-gray-600"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default LoyalityAccount;
